import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleJob {
  isLoading: boolean;
  isModalLoading: boolean;
  isRequestSuccessful: boolean;
  job: any;
  jobList: any[];
  jobCount: number;
}
const InitialState: AnsibleJob = {
  isLoading: false,
  isModalLoading: false,
  isRequestSuccessful: false,
  job: {},
  jobList: [],
  jobCount: 0,
};
// actions
export const setJobLoading = createAction<boolean>('job/setJobLoading');
export const setJobModalLoading = createAction<boolean>('job/setJobModalLoading');
export const setRequestSuccessful = createAction<boolean>('job/setRequestSuccessful');
export const jobListLoaded = createAction<any>('job/jobListLoaded');
export const setJobObject = createAction<any>('job/setJobObject');

const AnsiblejobReducer = createReducer(InitialState, {
  [setJobLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },

  [setJobModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setJobObject.type]: (state, action) => {
    state.job = action.payload;
    state.isModalLoading = false;
    state.isRequestSuccessful = false;
    state.isLoading = false;
  },
  [jobListLoaded.type]: (state, action) => {
    state.jobList = action.payload.results;
    state.jobCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
});

export default AnsiblejobReducer;
