import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleOrganization {
  orgList: any[];
  orgCount: number;
  orgUserCount: number;
  orgPermissionCount: number;
  orgNotificationCount: number;
  permissionOrgRoleObj: any;
  usersDataDisplay: any[];
  organizationTeamList: any[];
  orgPermissionList: any[];
  organizationNotificationList: any[];
  orgAccessList: any[];
  organization: any;
  isLoading: boolean;
  isModalLoading: boolean;
  isRequestSuccessful: boolean;
}
const InitialState: AnsibleOrganization = {
  orgList: [],
  orgCount: 0,
  usersDataDisplay: [],
  organizationTeamList: [],
  orgPermissionList: [],
  organizationNotificationList: [],
  permissionOrgRoleObj: {},
  orgAccessList: [],
  orgUserCount: 0,
  orgPermissionCount: 0,
  orgNotificationCount: 0,
  organization: {},
  isLoading: false,
  isModalLoading: false,
  isRequestSuccessful: false,
};
// actions
export const setOrgLoading = createAction<boolean>('organization/setOrgLoading');
export const setRequestSuccessful = createAction<boolean>('organization/setRequestSuccessful');
export const orgListLoaded = createAction<any>('organization/orgListLoaded');
export const organizationTeamListLoaded = createAction<any>('organization/organizationTeamListLoaded');
export const orgPermissionLoaded = createAction<any>('organization/orgPermissionLoaded');
export const organizationNotificationListLoaded = createAction<any>('organization/organizationNotificationListLoaded');
export const setOrgModalLoading = createAction<boolean>('organization/setOrgModalLoading');
export const setOrgObject = createAction<any>('organization/setOrgObject');
export const setPermissionOrgRoleObj = createAction<any>('organization/setPermissionOrgRoleObj');
export const orgAccessListLoaded = createAction<any>('organization/orgAccessListLoaded');

const AnsibleOrganizationReducer = createReducer(InitialState, {
  [setOrgLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setOrgModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [orgListLoaded.type]: (state, action) => {
    state.orgList = action.payload.results;
    state.orgCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [setOrgObject.type]: (state, action) => {
    state.organization = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [setPermissionOrgRoleObj.type]: (state, action) => {
    state.permissionOrgRoleObj = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [organizationTeamListLoaded.type]: (state, action) => {
    state.organizationTeamList = action.payload;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [orgPermissionLoaded.type]: (state, action) => {
    state.orgPermissionList = action.payload.results;
    state.orgPermissionCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
    state.isModalLoading = false;
  },
  [organizationNotificationListLoaded.type]: (state, action) => {
    state.organizationNotificationList = action.payload.results;
    state.orgNotificationCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [orgAccessListLoaded.type]: (state, action) => {
    state.orgAccessList = action.payload.results;
    state.orgUserCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
});

export default AnsibleOrganizationReducer;
