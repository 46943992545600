import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleProject {
  projectDetails: any[];
  projectUserList: any[];
  projectPermissionList: any[];
  projectPermissionCount: number;
  isLoading: boolean;
  isModalLoading: boolean;
  project: any;
  schedule: any;
  projectList: any[];
  projectCount: number;
  projectNotificationList: any[];
  projectNotificationCount: number;
  projectSchedule: [];
  projectScheduleCount: number;
  projectJobTemplateList: any;
  projectJobTemplateCount: number;
  isRequestSuccessful: boolean;
}
const InitialState: AnsibleProject = {
  projectDetails: [],
  projectUserList: [],
  projectPermissionList: [],
  projectPermissionCount: 0,
  isLoading: false,
  isModalLoading: false,
  project: {},
  schedule: {},
  projectList: [],
  projectCount: 0,
  projectNotificationList: [],
  projectNotificationCount: 0,
  projectSchedule: [],
  projectScheduleCount: 0,
  projectJobTemplateList: [],
  projectJobTemplateCount: 0,
  isRequestSuccessful: false,
};
// actions
export const setProjectLoading = createAction<boolean>('project/setProjectLoading');
export const loadProjectDetails = createAction<any>('project/loadProjectDetails');
export const setProjectModalLoading = createAction<boolean>('project/setProjectModalLoading');
export const projectListLoaded = createAction<any>('project/projectListLoaded');
export const projectUserListLoaded = createAction<any>('project/projectUserListLoaded');
export const projectPermissionListLoaded = createAction<any>('project/projectPermissionListLoaded');
export const setProjectObject = createAction<any>('project/setProjectObject');
export const setScheduleObject = createAction<any>('project/setScheduleObject');
export const projectNotificationListLoaded = createAction<any>('project/projectNotificationListLoaded');
export const projectScheduleData = createAction<any>('project/projectScheduleData');
export const projectJobTemplateData = createAction<any>('project/projectJobTemplateData');
export const setRequestSuccessful = createAction<boolean>('project/setRequestSuccessful');

const AnsibleprojectReducer = createReducer(InitialState, {
  [setProjectLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [loadProjectDetails.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setProjectModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setProjectObject.type]: (state, action) => {
    state.project = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [setScheduleObject.type]: (state, action) => {
    state.schedule = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [projectListLoaded.type]: (state, action) => {
    state.projectList = action.payload.results;
    state.projectCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [projectUserListLoaded.type]: (state, action) => {
    state.projectUserList = action.payload;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [projectPermissionListLoaded.type]: (state, action) => {
    state.projectPermissionList = action.payload.results;
    state.projectPermissionCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [projectNotificationListLoaded.type]: (state, action) => {
    state.projectNotificationList = action.payload.results;
    state.projectNotificationCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [projectScheduleData.type]: (state, action) => {
    state.projectSchedule = action.payload.results;
    state.projectScheduleCount = action.payload.count;
    state.isLoading = false;
  },
  [projectJobTemplateData.type]: (state, action) => {
    state.projectJobTemplateList = action.payload;
    state.projectJobTemplateCount = action.payload.length;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
});

export default AnsibleprojectReducer;
