import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleJobs {
  isLoading: boolean;
  isModalLoading: boolean;
  license: any;
  isRequestSuccessful: boolean;
}
const InitialState: AnsibleJobs = {
  isLoading: false,
  isModalLoading: false,
  license: {},
  isRequestSuccessful: false,
};
// actions
export const setLicenseLoading = createAction<boolean>('license/setLicenseLoading');
export const setLicenseModalLoading = createAction<boolean>('license/setLicenseModalLoading');
export const setLicenseObject = createAction<any>('license/setLicenseObject');
export const setRequestSuccessful = createAction<boolean>('license/setRequestSuccessful');

const AnsibleSettingsLicenseReducer = createReducer(InitialState, {
  [setLicenseLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setLicenseModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setLicenseObject.type]: (state, action) => {
    state.license = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
});

export default AnsibleSettingsLicenseReducer;
