import { createAction, createReducer } from '@reduxjs/toolkit';

export interface ResourceReducer {
  sourceList: any[];
  scheduleList: any[];
  sourceNotificationList: any[];
  source: any;
  schedule: any;
  isLoading: boolean;
  isModalLoading: boolean;
  sourceCount: number;
  scheduleListCount: number;
  sourceNotificationCount: number;
  isRequestSuccessful: boolean;
}
const InitialState: ResourceReducer = {
  sourceList: [],
  scheduleList: [],
  sourceNotificationList: [],
  source: {},
  schedule: {},
  isLoading: false,
  isModalLoading: false,
  sourceCount: 0,
  scheduleListCount: 0,
  sourceNotificationCount: 0,
  isRequestSuccessful: false,
};
// actions
export const setResourceLoading = createAction<boolean>('resource/setResourceLoading');
export const resourceListLoaded = createAction<any>('resource/resourceListLoaded');
export const scheduleListLoaded = createAction<any>('resource/scheduleListLoaded');
export const sourcenotificationListLoaded = createAction<any>('resource/sourcenotificationListLoaded');
export const setResourceModalLoading = createAction<boolean>('resource/setResourceModalLoading');
export const setResourceObject = createAction<any>('resource/setResourceObject');
export const setScheduleObject = createAction<any>('resource/setScheduleObject');
export const setRequestSuccessful = createAction<boolean>('resource/setRequestSuccessful');

const AnsibleSourceReducer = createReducer(InitialState, {
  [setResourceLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setResourceModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [resourceListLoaded.type]: (state, action) => {
    state.isLoading = false;
    state.sourceList = action.payload.results;
    state.sourceCount = action.payload.count;
    state.isRequestSuccessful = false;
  },
  [sourcenotificationListLoaded.type]: (state, action) => {
    state.sourceNotificationList = action.payload.results;
    state.sourceNotificationCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [scheduleListLoaded.type]: (state, action) => {
    state.scheduleList = action.payload.results;
    state.scheduleListCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [setResourceObject.type]: (state, action) => {
    state.source = action.payload;
    state.isModalLoading = false;
    state.isLoading = false;
  },
  [setScheduleObject.type]: (state, action) => {
    state.schedule = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
});

export default AnsibleSourceReducer;
