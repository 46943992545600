import { createAction, createReducer } from '@reduxjs/toolkit';
import { ActivityLog, ServiceNowTicket } from '../../../types/automation/servicenow';

export interface Automation {
  inventoryList: any[];
  classificationList: any[];
  classificationCount: number;
  ticketCount: number;
  classification: any;
  inventory: any;
  isLoading: boolean;
  isModalLoading: boolean;
  serviceNowTicketUpdatesList: Array<{ group: any; value: ActivityLog[] }>;
  serviceNowTicketsList: ServiceNowTicket[];
  hasMoreData: boolean;
}

const InitialState: Automation = {
  inventoryList: [],
  classificationList: [],
  classificationCount: 0,
  ticketCount: 0,
  classification: {},
  inventory: {},
  isLoading: false,
  isModalLoading: false,
  serviceNowTicketUpdatesList: [],
  serviceNowTicketsList: [],
  hasMoreData: true,
};
export const setAutomationLoading = createAction<boolean>('automation/setAutomationLoading');
export const setAutomationModalLoading = createAction<boolean>('automation/setAutomationModalLoading');
export const inventoryLoading = createAction<any[]>('automation/inventoryLoading');
export const dashboardLoading = createAction<any[]>('automation/dashboardLoading');
export const activityLogLoading = createAction<any[]>('automation/activityLogLoading');
export const ticketsLoading = createAction<any[]>('automation/ticketsLoading');
export const classificationLoading = createAction<any[]>('automation/classificationLoading');
export const setClassificationModalLoading = createAction<boolean>('automation/setClassificationModalLoading');
export const setClassificationObject = createAction<any>('automation/setClassificationObject');
export const setInventoryObject = createAction<any>('automation/setInventoryObject');

const AutomationReducer = createReducer(InitialState, {
  [setAutomationLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setClassificationModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setAutomationModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [inventoryLoading.type]: (state, action) => {
    state.inventoryList = action.payload;
    state.isLoading = false;
  },
  [dashboardLoading.type]: (state, action) => {
    // state.inventoryList = action.payload;
    state.isLoading = false;
  },
  [activityLogLoading.type]: (state, action) => {
    state.serviceNowTicketUpdatesList = action.payload;
    state.isLoading = false;
  },
  [ticketsLoading.type]: (state, action) => {
    state.serviceNowTicketsList = action.payload;
    if (action.payload.length < 10) {
      state.hasMoreData = false;
    }
    state.ticketCount = action.payload.length;
    state.isLoading = false;
  },
  [classificationLoading.type]: (state, action) => {
    state.classificationList = action.payload;
    state.classificationCount = action.payload.length;
    state.isLoading = false;
  },
  [setClassificationObject.type]: (state, action) => {
    state.classification = action.payload;
    state.isModalLoading = false;
  },
  [setInventoryObject.type]: (state, action) => {
    state.inventory = action.payload;
    state.isModalLoading = false;
  },
});

export default AutomationReducer;
