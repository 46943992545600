import { createAction, createReducer } from '@reduxjs/toolkit';

export interface DataCollectorPermission {
  isLoading: boolean;
  isModalLoading: boolean;
  isRequestSuccessful: boolean;
  PermissionList: any;
}
const InitialState: DataCollectorPermission = {
  isLoading: false,
  isModalLoading: false,
  isRequestSuccessful: false,
  PermissionList: [],
};
// actions
export const setDataCollectorLoading = createAction<boolean>('DCPermissionReducer/setDataCollectorLoading');
export const setModalLoading = createAction<boolean>('DCPermissionReducer/setModalLoading');
export const setRequestSuccessful = createAction<boolean>('DCPermissionReducer/setRequestSuccessful');
export const DCPermissionListLoaded = createAction<any>('DCPermissionReducer/DCPermissionListLoaded');

const DCPermissionReducer = createReducer(InitialState, {
  [setDataCollectorLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [DCPermissionListLoaded.type]: (state, action) => {
    state.isLoading = false;
    state.PermissionList = action.payload;
  },
});

export default DCPermissionReducer;
