import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleUser {
  UserDetails: any[];
  isLoading: boolean;
  isModalLoading: boolean;
  permissionJobSelectedObject: any;
  permissionWorkflowSelectedObject: any;
  permissionProjectSelectedObject: any;
  permissionInventorySelectedObject: any;
  permissionCredentialSelectedObject: any;
  permissionOrganizationSelectedObject: any;
  user: any;
  team: any;
  userList: any[];
  userCount: number;
  userOrganizationCount: number;
  userPermissionCount: number;
  userOrganizationList: any[];
  userPermissionList: any[];
  userTeamlist: any[];
  userTeamCount: number;
  userPermission: any[];
  isRequestSuccessful: boolean;
}
const InitialState: AnsibleUser = {
  UserDetails: [],
  isLoading: false,
  isModalLoading: false,
  permissionJobSelectedObject: {},
  permissionWorkflowSelectedObject: {},
  permissionProjectSelectedObject: {},
  permissionInventorySelectedObject: {},
  permissionCredentialSelectedObject: {},
  permissionOrganizationSelectedObject: {},
  user: {},
  team: {},
  userList: [],
  userCount: 0,
  userOrganizationCount: 0,
  userPermissionCount: 0,
  userOrganizationList: [],
  userPermissionList: [],
  userTeamlist: [],
  userTeamCount: 0,
  userPermission: [],
  isRequestSuccessful: false,
};
// actions
export const setUserLoading = createAction<boolean>('user/setOrgLoading');
export const loadUserDetails = createAction<any>('user/loadUserDetails');
export const setUserModalLoading = createAction<boolean>('user/setUserModalLoading');
export const userListLoaded = createAction<any>('user/userListLoaded');
export const setUserObject = createAction<any>('user/setUserObject');
export const setUserTeamObject = createAction<any>('user/setUserTeamObject');
export const userOrganizationListLoaded = createAction<any[]>('user/userOrganizationListLoaded');
export const userTeamList = createAction<any[]>('user/userTeamList');
export const userPermissionList = createAction<any[]>('user/userPermissionList');
export const userPermissionListLoaded = createAction<any[]>('user/userPermissionListLoaded');
export const setRequestSuccessful = createAction<boolean>('user/setRequestSuccessful');
export const setPermissionJobSelectedObject = createAction<boolean>('user/setPermissionJobSelectedObject');
export const setPermissionWorkflowSelectedObject = createAction<boolean>('user/setPermissionWorkflowSelectedObject');
export const setPermissionProjectSelectedObject = createAction<boolean>('user/setPermissionProjectSelectedObject');
export const setPermissionInventorySelectedObject = createAction<boolean>('user/setPermissionInventorySelectedObject');
export const setPermissionCredentialSelectedObject = createAction<boolean>(
  'user/setPermissionCredentialSelectedObject'
);
export const setPermissionOrganizationSelectedObject = createAction<boolean>(
  'user/setPermissionOrganizationSelectedObject'
);

const AnsibleUserReducer = createReducer(InitialState, {
  [setUserLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [userOrganizationListLoaded.type]: (state, action) => {
    state.userOrganizationList = action.payload.results;
    state.userOrganizationCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [loadUserDetails.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setUserModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setUserObject.type]: (state, action) => {
    state.user = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [setUserTeamObject.type]: (state, action) => {
    state.team = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [setPermissionJobSelectedObject.type]: (state, action) => {
    state.permissionJobSelectedObject = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [setPermissionWorkflowSelectedObject.type]: (state, action) => {
    state.permissionWorkflowSelectedObject = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [setPermissionProjectSelectedObject.type]: (state, action) => {
    state.permissionProjectSelectedObject = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [setPermissionInventorySelectedObject.type]: (state, action) => {
    state.permissionInventorySelectedObject = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [setPermissionCredentialSelectedObject.type]: (state, action) => {
    state.permissionCredentialSelectedObject = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [setPermissionOrganizationSelectedObject.type]: (state, action) => {
    state.permissionOrganizationSelectedObject = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [userListLoaded.type]: (state, action) => {
    state.userList = action.payload.results;
    state.userCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
    state.isModalLoading = false;
  },
  [userTeamList.type]: (state, action) => {
    state.userTeamlist = action.payload.results;
    state.userTeamCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
    state.isModalLoading = false;
  },
  [userPermissionListLoaded.type]: (state, action) => {
    state.userPermissionList = action.payload.results;
    state.userPermissionCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
    state.isModalLoading = false;
  },
});

export default AnsibleUserReducer;
