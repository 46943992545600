import { createAction, createReducer } from '@reduxjs/toolkit';

export interface InventoryReducer {
  inventoryList: any[];
  inventoryListCount: number;
  inventoryPermissionList: any[];
  jobList: any[];
  inventoryGroupList: any[];
  inventoryGroupObj: any;
  inventory: any;
  isLoading: boolean;
  isModalLoading: boolean;
  isRequestSuccessful: boolean;
  inventoryPermissionCount: number;
  completeJobCount: number;
  hostList: any[];
  hostCount: number;
  host: any[];
}
const InitialState: InventoryReducer = {
  inventoryList: [],
  inventoryListCount: 0,
  inventoryPermissionList: [],
  jobList: [],
  inventoryGroupList: [],
  inventoryGroupObj: {},
  inventory: {},
  isLoading: false,
  isModalLoading: false,
  isRequestSuccessful: false,
  inventoryPermissionCount: 0,
  completeJobCount: 0,
  hostList: [],
  hostCount: 0,
  host: [],
};
// actions
export const setInventoryLoading = createAction<boolean>('inventory/setInventoryLoading');
export const inventoryListLoaded = createAction<any>('inventory/InventoryListLoaded');
export const inventoryPermissionListLoaded = createAction<any>('inventory/inventoryPermissionListLoaded');
export const inventoryJobsLoaded = createAction<any>('inventory/inventoryJobsLoaded');
export const inventoryGroupListLoaded = createAction<any>('inventory/inventoryGroupListLoaded');
export const setInventoryModalLoading = createAction<boolean>('inventory/setInventoryModalLoading');
export const setInventoryObject = createAction<any>('inventory/setInventoryObject');
export const setInventoryGroupObject = createAction<any>('inventory/setInventoryGroupObject');
export const setRequestSuccessful = createAction<any>('inventory/setRequestSuccessful');
export const setHostListObject = createAction<any>('inventory/setHostListObject');
export const hostListLoaded = createAction<any>('inventory/hostListLoaded');

const AnsibleInventoryReducer = createReducer(InitialState, {
  [setInventoryLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setInventoryModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [inventoryListLoaded.type]: (state, action) => {
    state.inventoryList = action.payload.results;
    state.inventoryListCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [inventoryJobsLoaded.type]: (state, action) => {
    state.isLoading = false;
    state.jobList = action.payload.results;
    state.completeJobCount = action.payload.count;
    state.isRequestSuccessful = false;
  },
  [setInventoryGroupObject.type]: (state, action) => {
    state.inventoryGroupObj = action.payload;
    state.isModalLoading = false;
  },
  [inventoryPermissionListLoaded.type]: (state, action) => {
    state.inventoryPermissionList = action.payload.results;
    state.inventoryPermissionCount = action.payload.count;
    state.isRequestSuccessful = false;
    state.isLoading = false;
  },
  [inventoryGroupListLoaded.type]: (state, action) => {
    state.inventoryGroupList = action.payload;
    state.isModalLoading = false;
  },
  [setInventoryObject.type]: (state, action) => {
    state.inventory = action.payload;
    state.isModalLoading = false;
    state.isLoading = false;
  },
  [setHostListObject.type]: (state, action) => {
    state.host = action.payload;
    state.isModalLoading = false;
    state.isLoading = false;
  },
  [hostListLoaded.type]: (state, action) => {
    state.isLoading = false;
    state.hostList = action.payload.results;
    state.hostCount = action.payload.count;
    state.isRequestSuccessful = false;
  },
});

export default AnsibleInventoryReducer;
