import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleManagementJob {
  isLoading: boolean;
  isModalLoading: boolean;
  managementJob: any;
  managementJobList: any[];
  notificationsList: any[];
  scheduleList: any[];
  scheduleObject: any;
  managementJobCount: number;
  scheduleListCount: number;
  notificationsListCount: number;
  isRequestSuccessful: boolean;
}
const InitialState: AnsibleManagementJob = {
  isLoading: false,
  isModalLoading: false,
  managementJob: {},
  managementJobList: [],
  notificationsList: [],
  scheduleList: [],
  scheduleObject: {},
  managementJobCount: 0,
  scheduleListCount: 0,
  notificationsListCount: 0,
  isRequestSuccessful: false,
};
// actions
export const setManagementJobLoading = createAction<boolean>('managementJob/setManagementJobLoading');
export const setManagementJobModalLoading = createAction<boolean>('managementJob/setManagementJobModalLoading');
export const managementJobListLoaded = createAction<any>('managementJob/managementJobListLoaded');
export const notificationListLoaded = createAction<any>('managementJob/notificationListLoaded');
export const scheduleListLoaded = createAction<any>('managementJob/scheduleListLoaded');
export const setManagementJobObject = createAction<any>('managementJob/setManagementJobObject');
export const setScheduleObject = createAction<any>('managementJob/setScheduleObject');
export const setRequestSuccessful = createAction<any>('managementJob/setRequestSuccessful');

const AnsibleManagementJobReducer = createReducer(InitialState, {
  [setManagementJobLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },

  [setManagementJobModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setManagementJobObject.type]: (state, action) => {
    state.managementJob = action.payload;
    state.isModalLoading = false;
  },
  [setScheduleObject.type]: (state, action) => {
    state.scheduleObject = action.payload;
    state.isModalLoading = false;
    state.isLoading = false;
  },
  [managementJobListLoaded.type]: (state, action) => {
    state.isLoading = false;
    state.managementJobList = action.payload.results;
    state.managementJobCount = action.payload.count;
    state.isRequestSuccessful = false;
  },
  [notificationListLoaded.type]: (state, action) => {
    state.notificationsList = action.payload.results;
    state.notificationsListCount = action.payload.count;
    state.isRequestSuccessful = false;
    state.isLoading = false;
  },
  [scheduleListLoaded.type]: (state, action) => {
    state.scheduleList = action.payload.results;
    state.scheduleListCount = action.payload.count;
    state.isRequestSuccessful = false;
    state.isLoading = false;
  },
});

export default AnsibleManagementJobReducer;
