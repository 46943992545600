import { AnyAction, combineReducers } from 'redux';
import { CleanUp, cleanUpAction } from '../actions/cleanUp';
import sharedReducers from 'app/core/reducers';
import alertingReducers from 'app/features/alerting/state/reducers';
import teamsReducers from 'app/features/teams/state/reducers';
import apiKeysReducers from 'app/features/api-keys/state/reducers';
import foldersReducers from 'app/features/folders/state/reducers';
import dashboardReducers from 'app/features/dashboard/state/reducers';
import exploreReducers from 'app/features/explore/state/main';
import { reducer as pluginsReducer } from 'app/features/plugins/admin/state/reducer';
import dataSourcesReducers from 'app/features/datasources/state/reducers';
import usersReducers from 'app/features/users/state/reducers';
import userReducers from 'app/features/profile/state/reducers';
import organizationReducers from 'app/features/org/state/reducers';
import ldapReducers from 'app/features/admin/state/reducers';
import templatingReducers from 'app/features/variables/state/reducers';
import importDashboardReducers from 'app/features/manage-dashboards/state/reducers';
import panelEditorReducers from 'app/features/dashboard/components/PanelEditor/state/reducers';
import panelsReducers from 'app/features/panel/state/reducers';
import AnsibleOrganizationReducer from '../../features/ansible/organization/state/reducer';
import AnsibleUserReducer from '../../features/ansible/user/state/reducer';
import AutomationReducer from '../../features/automation/state/reducer';
import AnsibleTeamReducer from '../../features/ansible/team/state/reducer';
import AnsibleCredentialReducer from '../../features/ansible/credential/state/reducer';
import AnsibleinventoryScriptReducer from '../../features/ansible/inventaryScript/state/reducer';
import AnsibleInventoryReducer from '../../features/ansible/inventory/state/reducer';
import AnsibleGroupReducer from '../../features/ansible/inventory/group/state/reducer';
import AnsibleprojectReducer from '../../features/ansible/project/state/reducer';
import AnsibleHostReducer from '../../features/ansible/inventory/host/state/reducer';
import AnsibleSourceReducer from '../../features/ansible/inventory/source/state/reducer';
import AnsibleTemplateReducer from '../../features/ansible/template/state/reducer';
import AnsibleCredentialTypeReducer from '../../features/ansible/credentialType/state/reducer';
import ScheduleReducer from '../../features/automation/schedule/state/reducer';
import AnsibleInstanceGroupReducer from '../../features/ansible/instanceGroups/state/reducer';
import AnsibleApplicationReducer from '../../features/ansible/applicaiton/state/reducer';
import AnsibleNotificationReducer from '../../features/ansible/notification/state/reducer';
import AnsibleManagementJobReducer from '../../features/ansible/managementJob/state/reducer';
import AnsiblejobReducer from '../../features/ansible/job/state/reducer';
import AnsibleScheduleReducer from '../../features/ansible/schedule/state/reducer';
import AnsibleMyViewReducer from '../../features/ansible/myView/state/reducer';
import AnsibleDashboardReducer from '../../features/ansible/dashboard/state/reducer';
import AnsibleSystemReducer from '../../features/ansible/setting/system/state/reducer';
import AnsibleSettingsJobsReducer from '../../features/ansible/setting/jobs/state/reducer';
import AnsibleSettingsUserInterfaceReducer from '../../features/ansible/setting/userInterface/state/reducer';
import AnsibleSettingsLicenseReducer from '../../features/ansible/setting/license/state/reducer';
import AnsibleAuthenticationReducer from '../../features/ansible/setting/authentication/state/reducer';
import AnsibleVisualizerReducer from '../../features/ansible/visualizer/state/reducer';
import AnsibleDataCollectorReducer from 'app/features/dataCollector/dag/state/reducer';
import AnsibleRolesReducer from 'app/features/dataCollector/role/state/reducer';
import DCActionsReducer from '../../features/dataCollector/actions/state/reducer';
import RtsConfigReducer from '../../features/ansible/configurationState/reducer';
import DCPermissionReducer from 'app/features/dataCollector/permisions/state/reducer';
import AIMLReducer from 'app/features/ai-ml/state/reducer';

const rootReducers = {
  ...sharedReducers,
  ...alertingReducers,
  ...teamsReducers,
  ...apiKeysReducers,
  ...foldersReducers,
  ...dashboardReducers,
  ...exploreReducers,
  ...dataSourcesReducers,
  ...usersReducers,
  ...userReducers,
  ...organizationReducers,
  ...ldapReducers,
  ...templatingReducers,
  ...importDashboardReducers,
  ...panelEditorReducers,
  ...panelsReducers,
  plugins: pluginsReducer,
};

const addedReducers = {
  ansibleOrg: AnsibleOrganizationReducer,
  automation: AutomationReducer,
  schedule: ScheduleReducer,
  ansibleUser: AnsibleUserReducer,
  ansibleTeam: AnsibleTeamReducer,
  ansibleInventory: AnsibleInventoryReducer,
  ansibleGroup: AnsibleGroupReducer,
  ansibleHost: AnsibleHostReducer,
  ansibleSource: AnsibleSourceReducer,
  ansibleCredntial: AnsibleCredentialReducer,
  ansibleInventoryScript: AnsibleinventoryScriptReducer,
  ansibleJob: AnsiblejobReducer,
  ansibleSchedule: AnsibleScheduleReducer,
  ansibleMyView: AnsibleMyViewReducer,
  ansibleProject: AnsibleprojectReducer,
  ansibleTemplate: AnsibleTemplateReducer,
  ansibleCredentialType: AnsibleCredentialTypeReducer,
  ansibleInstanceGroup: AnsibleInstanceGroupReducer,
  ansibleApplicaiton: AnsibleApplicationReducer,
  ansibleNotificationReducer: AnsibleNotificationReducer,
  ansibleManagementJob: AnsibleManagementJobReducer,
  ansibleDashboard: AnsibleDashboardReducer,
  ansibleSystemReducer: AnsibleSystemReducer,
  ansibleSettingsJobs: AnsibleSettingsJobsReducer,
  AnsibleSettingsUserInterface: AnsibleSettingsUserInterfaceReducer,
  AnsibleSettingsLicense: AnsibleSettingsLicenseReducer,
  ansibleAuthenticationReducer: AnsibleAuthenticationReducer,
  ansibleVisualizerReducer: AnsibleVisualizerReducer,
  ansibleDataCollectorReducer: AnsibleDataCollectorReducer,
  ansibleRolesReducer: AnsibleRolesReducer,
  dcActionsReducer: DCActionsReducer,
  dcPermissionReducer: DCPermissionReducer,
  rtsConfigReducer: RtsConfigReducer,
  aimlReducer: AIMLReducer,
};

export const addReducer = (newReducers: any) => {
  Object.assign(addedReducers, newReducers);
};

export const createRootReducer = () => {
  const appReducer = combineReducers({
    ...rootReducers,
    ...addedReducers,
  });

  return (state: any, action: AnyAction) => {
    if (action.type !== cleanUpAction.type) {
      return appReducer(state, action);
    }
    const { stateSelector } = action.payload as CleanUp<any>;
    const stateSlice = stateSelector(state);
    recursiveCleanState(state, stateSlice);
    return appReducer(state, action);
  };
};

export const recursiveCleanState = (state: any, stateSlice: any): boolean => {
  for (const stateKey in state) {
    if (!state.hasOwnProperty(stateKey)) {
      continue;
    }

    const slice = state[stateKey];
    if (slice === stateSlice) {
      state[stateKey] = undefined;
      return true;
    }

    if (typeof slice === 'object') {
      const cleaned = recursiveCleanState(slice, stateSlice);
      if (cleaned) {
        return true;
      }
    }
  }

  return false;
};
