import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleMyView {
  isLoading: boolean;
  isModalLoading: boolean;
  jobTemplateList: any[];
  workflowList: any[];
  jobList: any[];
  jobCount: number;
  workTemplateCount: number;
  jobTemplateCount: number;
  isRequestSuccessful: boolean;
  isWorkFlowLoading: boolean;
  isJobLoading: boolean;
}
const InitialState: AnsibleMyView = {
  isLoading: false,
  isWorkFlowLoading: false,
  isJobLoading: false,
  isModalLoading: false,
  jobTemplateList: [],
  workflowList: [],
  jobList: [],
  jobCount: 0,
  workTemplateCount: 0,
  jobTemplateCount: 0,
  isRequestSuccessful: false,
};
// actions
export const setMyViewLoading = createAction<boolean>('myView/setMyViewLoading');
export const setMyViewWorkTemplateLoading = createAction<boolean>('myView/setWorkTemplateLoading');
export const setJobLoading = createAction<boolean>('myView/setJobLoading');
export const setMyViewModalLoading = createAction<boolean>('myView/setMyViewModalLoading');
export const jobTemplateListLoaded = createAction<any>('myView/jobTemplateListLoaded');
export const workflowListLoaded = createAction<any>('myView/workflowListLoaded');
export const jobList = createAction<any>('myView/jobList');

const AnsibleTemplateReducer = createReducer(InitialState, {
  [setMyViewLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setMyViewWorkTemplateLoading.type]: (state, action) => {
    state.isWorkFlowLoading = action.payload;
  },
  [setJobLoading.type]: (state, action) => {
    state.isJobLoading = action.payload;
  },
  [setMyViewModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [jobTemplateListLoaded.type]: (state, action) => {
    state.jobTemplateList = action.payload.results;
    state.jobTemplateCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [workflowListLoaded.type]: (state, action) => {
    state.workflowList = action.payload.results;
    state.workTemplateCount = action.payload.count;
    state.isRequestSuccessful = false;
    state.isWorkFlowLoading = false;
  },
  [jobList.type]: (state, action) => {
    state.jobList = action.payload.results;
    state.jobCount = action.payload.count;
    state.isRequestSuccessful = false;
    state.isJobLoading = false;
  },
});

export default AnsibleTemplateReducer;
