import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleInstanceGroup {
  isLoading: boolean;
  isModalLoading: boolean;
  instanceGroup: any;
  instanceGroupList: any[];
  instanceGroupCount: number;
  instanceCount: number;
  jobListCount: number;
  instanceList: any[];
  instanceObject: any;
  jobList: any[];
  isRequestSuccessful: boolean;
}
const InitialState: AnsibleInstanceGroup = {
  isLoading: false,
  isModalLoading: false,
  instanceGroup: {},
  instanceGroupList: [],
  instanceGroupCount: 0,
  jobListCount: 0,
  instanceCount: 0,
  instanceList: [],
  instanceObject: {},
  jobList: [],
  isRequestSuccessful: false,
};
// actions
export const setInstanceGroupLoading = createAction<boolean>('instanceGroup/setInstanceGroupLoading');
export const setInstanceGroupModalLoading = createAction<boolean>('instanceGroup/setInstanceGroupModalLoading');
export const instanceGroupListLoaded = createAction<any>('instanceGroup/instanceGroupListLoaded');
export const instancesListLoaded = createAction<any>('instanceGroup/instancesListLoaded');
export const jobsListLoaded = createAction<any>('instanceGroup/jobsListLoaded');
export const setInstanceGroupObject = createAction<any>('instanceGroup/setInstanceGroupObject');
export const setInstancepObject = createAction<any>('instanceGroup/setInstancepObject');
export const setRequestSuccessful = createAction<any>('instanceGroup/setRequestSuccessful');

const AnsibleInstanceGroupReducer = createReducer(InitialState, {
  [setInstanceGroupLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setInstanceGroupModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setInstanceGroupObject.type]: (state, action) => {
    state.instanceGroup = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [setInstancepObject.type]: (state, action) => {
    state.instanceGroup = action.payload;
    state.isModalLoading = false;
  },
  [instanceGroupListLoaded.type]: (state, action) => {
    state.instanceGroupList = action.payload.results;
    state.instanceGroupCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [instancesListLoaded.type]: (state, action) => {
    state.instanceList = action.payload.results;
    state.instanceCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [jobsListLoaded.type]: (state, action) => {
    state.jobList = action.payload.results;
    state.jobListCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
});

export default AnsibleInstanceGroupReducer;
