import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleSystem {
  isLoading: boolean;
  isModalLoading: boolean;
  system: any;
  isRequestSuccessful: boolean;
}
const InitialState: AnsibleSystem = {
  isLoading: false,
  isModalLoading: false,
  system: {},
  isRequestSuccessful: false,
};
// actions
export const setSystemLoading = createAction<boolean>('system/setSystemLoading');
export const setSystemModalLoading = createAction<boolean>('system/setSystemModalLoading');
export const setSystemObject = createAction<any>('system/setSystemObject');
export const setRequestSuccessful = createAction<boolean>('system/setRequestSuccessful');

const AnsibleSystemReducer = createReducer(InitialState, {
  [setSystemLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setSystemModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setSystemObject.type]: (state, action) => {
    state.system = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
});

export default AnsibleSystemReducer;
