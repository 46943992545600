import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleTeam {
  teamDetails: any[];
  teamUserList: any[];
  teamUserCount: number;
  teamPermissionList: any[];
  teamPermissionCount: number;
  permissionJobSelectedObject: any;
  permissionWorkflowSelectedObject: any;
  permissionProjectSelectedObject: any;
  permissionInventorySelectedObject: any;
  permissionCredentialSelectedObject: any;
  permissionOrganizationSelectedObject: any;
  isLoading: boolean;
  isModalLoading: boolean;
  isRequestSuccessful: boolean;
  team: any;
  teamList: any[];
  teamListCount: number;
}
const InitialState: AnsibleTeam = {
  teamListCount: 0,
  teamDetails: [],
  teamUserList: [],
  teamUserCount: 0,
  teamPermissionList: [],
  teamPermissionCount: 0,
  permissionJobSelectedObject: {},
  permissionWorkflowSelectedObject: {},
  permissionProjectSelectedObject: {},
  permissionInventorySelectedObject: {},
  permissionCredentialSelectedObject: {},
  permissionOrganizationSelectedObject: {},
  isLoading: false,
  isModalLoading: false,
  team: {},
  teamList: [],
  isRequestSuccessful: false,
};
// actions
export const setTeamLoading = createAction<boolean>('team/setTeamLoading');
export const loadTeamDetails = createAction<any>('team/loadTeamDetails');
export const setTeamModalLoading = createAction<boolean>('team/setTeamModalLoading');
export const teamListLoaded = createAction<any>('team/TeamListLoaded');
export const teamUserListLoaded = createAction<any>('team/teamUserListLoaded');
export const teamPermissionListLoaded = createAction<any>('team/teamPermissionListLoaded');
export const setTeamObject = createAction<any>('team/setTeamObject');
export const setRequestSuccessful = createAction<any>('team/setRequestSuccessful');
export const setPermissionJobSelectedObject = createAction<boolean>('user/setPermissionJobSelectedObject');
export const setPermissionWorkflowSelectedObject = createAction<boolean>('user/setPermissionWorkflowSelectedObject');
export const setPermissionProjectSelectedObject = createAction<boolean>('user/setPermissionProjectSelectedObject');
export const setPermissionInventorySelectedObject = createAction<boolean>('user/setPermissionInventorySelectedObject');
export const setPermissionCredentialSelectedObject = createAction<boolean>(
  'user/setPermissionCredentialSelectedObject'
);
export const setPermissionOrganizationSelectedObject = createAction<boolean>(
  'user/setPermissionOrganizationSelectedObject'
);

const AnsibleTeamReducer = createReducer(InitialState, {
  [setTeamLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [loadTeamDetails.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setTeamModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setTeamObject.type]: (state, action) => {
    state.team = action.payload;
    state.isModalLoading = false;
    state.isLoading = false;
  },
  [teamListLoaded.type]: (state, action) => {
    state.teamList = action.payload.results;
    state.teamListCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [teamUserListLoaded.type]: (state, action) => {
    state.teamUserList = action.payload.results;
    state.teamUserCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [teamPermissionListLoaded.type]: (state, action) => {
    state.teamPermissionList = action.payload.results;
    state.teamPermissionCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
    state.isModalLoading = false;
  },
  [setPermissionJobSelectedObject.type]: (state, action) => {
    state.permissionJobSelectedObject = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [setPermissionWorkflowSelectedObject.type]: (state, action) => {
    state.permissionWorkflowSelectedObject = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [setPermissionProjectSelectedObject.type]: (state, action) => {
    state.permissionProjectSelectedObject = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [setPermissionInventorySelectedObject.type]: (state, action) => {
    state.permissionInventorySelectedObject = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [setPermissionCredentialSelectedObject.type]: (state, action) => {
    state.permissionCredentialSelectedObject = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [setPermissionOrganizationSelectedObject.type]: (state, action) => {
    state.permissionOrganizationSelectedObject = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
});

export default AnsibleTeamReducer;
