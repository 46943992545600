import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleVisualizer {
  isLoading: boolean;
  isModalLoading: boolean;
  isRequestSuccessful: boolean;
  isineventorySyncLoading: boolean;
  template: any[];
  project: any[];
  ineventorySync: any[];
  nodes: any;
}
const InitialState: AnsibleVisualizer = {
  isLoading: false,
  isModalLoading: false,
  isRequestSuccessful: false,
  isineventorySyncLoading: false,
  template: [],
  project: [],
  ineventorySync: [],
  nodes: [],
};
// actions
export const setVisualizerLoading = createAction<boolean>('visualizer/setVisualizerLoading');
export const setVisualizerModalLoading = createAction<boolean>('visualizer/setVisualizerModalLoading');
export const setRequestSuccessful = createAction<boolean>('visualizer/setRequestSuccessful');
export const setTemplateList = createAction<boolean>('visualizer/setTemplateList');
export const setNodesList = createAction<any>('visualizer/setNodesList');
export const setProjectList = createAction<boolean>('visualizer/setProjectList');
export const setineventorySyncList = createAction<boolean>('visualizer/setineventorySyncList');
export const setineventorySyncLoading = createAction<boolean>('visualizer/setineventorySyncLoading');

const AnsibleVisualizerReducer = createReducer(InitialState, {
  [setVisualizerLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setNodesList.type]: (state, action) => {
    state.nodes = action.payload.results;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [setVisualizerModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setTemplateList.type]: (state, action) => {
    state.isLoading = false;
    state.isRequestSuccessful = false;
    state.template = action.payload;
  },
  [setProjectList.type]: (state, action) => {
    state.isLoading = false;
    state.isRequestSuccessful = false;
    state.template = action.payload;
  },
  [setineventorySyncLoading.type]: (state, action) => {
    state.isineventorySyncLoading = action.payload;
  },
  [setineventorySyncList.type]: (state, action) => {
    state.isLoading = false;
    state.isRequestSuccessful = false;
    state.ineventorySync = action.payload;
  },
});

export default AnsibleVisualizerReducer;
