import axios from 'axios';
export const ansibleBaseURL = '/api/ansible/api/v2/';
export const ansibleHeaders = {
  'Content-Type': 'application/json',
};
export default {
  _post: axios.post,
  _get: axios.get,
  _put: axios.put,
  _delete: axios.delete,
  _request: axios.request,
  _patch: axios.patch,
};
