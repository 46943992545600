import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleJobs {
  isLoading: boolean;
  isModalLoading: boolean;
  userInterface: any;
  isRequestSuccessful: boolean;
}
const InitialState: AnsibleJobs = {
  isLoading: false,
  isModalLoading: false,
  userInterface: {},
  isRequestSuccessful: false,
};
// actions
export const setUserInterfaceLoading = createAction<boolean>('userInterface/setUserInterfaceLoading');
export const setUserInterfaceModalLoading = createAction<boolean>('userInterface/setUserInterfaceModalLoading');
export const setUserInterfaceObject = createAction<any>('userInterface/setUserInterfaceObject');
export const setRequestSuccessful = createAction<boolean>('userInterface/setRequestSuccessful');

const AnsibleSettingsUserInterfaceReducer = createReducer(InitialState, {
  [setUserInterfaceLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setUserInterfaceModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setUserInterfaceObject.type]: (state, action) => {
    state.userInterface = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
});

export default AnsibleSettingsUserInterfaceReducer;
