import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleApplication {
  applicationDetails: any[];
  isLoading: boolean;
  isModalLoading: boolean;
  application: any;
  applicationList: any[];
  applicationTok: any;
  applicationCount: number;
  applicationTokenCount: number;
  isRequestSuccessful: boolean;
}
const InitialState: AnsibleApplication = {
  applicationDetails: [],
  isLoading: false,
  isModalLoading: false,
  application: {},
  applicationList: [],
  applicationTok: [],
  applicationCount: 0,
  applicationTokenCount: 0,
  isRequestSuccessful: false,
};
// actions
export const setApplicationLoading = createAction<boolean>('applicaiton/setApplicationLoading');
export const loadApplicationDetails = createAction<any>('applicaiton/loadApplicationDetails');
export const setApplicationModalLoading = createAction<boolean>('applicaiton/setApplicationModalLoading');
export const ApplicationListLoaded = createAction<any>('applicaiton/ApplicationListLoaded');
export const setApplicationObject = createAction<any>('applicaiton/setApplicationObject');
export const setApplicationToken = createAction<any>('applicaiton/setApplicationToken');
export const setRequestSuccessful = createAction<any>('applicaiton/setRequestSuccessful');

const AnsibleApplicationReducer = createReducer(InitialState, {
  [setApplicationLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [loadApplicationDetails.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setApplicationModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setApplicationObject.type]: (state, action) => {
    state.application = action.payload;
    state.isModalLoading = false;
    state.isLoading = false;
  },
  [ApplicationListLoaded.type]: (state, action) => {
    state.applicationList = action.payload.results;
    state.applicationCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },

  [setApplicationToken.type]: (state, action) => {
    state.applicationTok = action.payload.results;
    state.applicationTokenCount = action.payload.count;
    state.isRequestSuccessful = false;
    state.isLoading = false;
  },
});

export default AnsibleApplicationReducer;
