import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleInventoryScript {
  isLoading: boolean;
  isModalLoading: boolean;
  inventoryScript: any;
  inventoryScriptList: any[];
  inventoryScriptCount: number;
  isRequestSuccessful: boolean;
}
const InitialState: AnsibleInventoryScript = {
  isLoading: false,
  isModalLoading: false,
  inventoryScript: {},
  inventoryScriptList: [],
  inventoryScriptCount: 0,
  isRequestSuccessful: false,
};
// actions
export const setInventoryScriptLoading = createAction<boolean>('inventoryScript/setInventoryScriptLoading');
export const setInventoryScriptModalLoading = createAction<boolean>('inventoryScript/setInventoryScriptModalLoading');
export const inventoryScriptListLoaded = createAction<any>('inventoryScript/inventoryScriptListLoaded');
export const setInventoryScriptObject = createAction<any>('inventoryScript/setInventoryScriptObject');
export const setRequestSuccessful = createAction<boolean>('inventoryScript/setRequestSuccessful');

const AnsibleinventoryScriptReducer = createReducer(InitialState, {
  [setInventoryScriptLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setInventoryScriptModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setInventoryScriptObject.type]: (state, action) => {
    state.inventoryScript = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
  [inventoryScriptListLoaded.type]: (state, action) => {
    state.inventoryScriptList = action.payload.results;
    state.inventoryScriptCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
});

export default AnsibleinventoryScriptReducer;
