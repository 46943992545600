// @ts-ignore
import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleSchedule {
  isLoading: boolean;
  isModalLoading: boolean;
  Schedule: any;
  ScheduleList: any[];
  scheduleCount: number;
  isRequestSuccessful: boolean;
}
const InitialState: AnsibleSchedule = {
  isLoading: false,
  isModalLoading: false,
  Schedule: {},
  ScheduleList: [],
  scheduleCount: 0,
  isRequestSuccessful: false,
};
// actions
export const setScheduleLoading = createAction<boolean>('schedules/setScheduleLoading');
export const setScheduleModalLoading = createAction<boolean>('schedules/setScheduleModalLoading');
export const ScheduleListLoaded = createAction<any>('schedules/ScheduleListLoaded');
export const setScheduleObject = createAction<any>('schedules/setScheduleObject');
export const setRequestSuccessful = createAction<any>('schedules/setRequestSuccessful');

// @ts-ignore
const AnsibleScheduleReducer = createReducer(InitialState, {
  [setScheduleLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },

  [setScheduleModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setScheduleObject.type]: (state, action) => {
    state.Schedule = action.payload;
    state.isModalLoading = false;
  },
  [ScheduleListLoaded.type]: (state, action) => {
    state.ScheduleList = action.payload.results;
    state.scheduleCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
});

export default AnsibleScheduleReducer;
