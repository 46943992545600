import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleDataCollector {
  isLoading: boolean;
  isModalLoading: boolean;
  isRequestSuccessful: boolean;
  roleList: any;
  roleDetail: any;
  DagGridList: any;
  role: any;
}
const InitialState: AnsibleDataCollector = {
  isLoading: false,
  isModalLoading: false,
  isRequestSuccessful: false,
  roleList: [],
  roleDetail: {},
  DagGridList: [],
  role: {},
};
// actions
export const setRoleLoading = createAction<boolean>('DataCollector/setJobLoading');
export const setRoleModalLoading = createAction<boolean>('DataCollector/setRoleModalLoading');
export const setRequestSuccessful = createAction<boolean>('DataCollector/setRequestSuccessful');
export const roleListLoaded = createAction<any>('DataCollector/DataCollectorListLoaded');
export const setRoleObj = createAction<any>('DataCollector/setRoleObj');
export const roleDetailLoaded = createAction<any>('DataCollector/DataCollectorDetailLoaded');
export const dataGridListLoad = createAction<any>('DataCollector/dataGridListLoad');

const AnsibleRolesReducer = createReducer(InitialState, {
  [setRoleLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRoleModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [roleListLoaded.type]: (state, action) => {
    state.isLoading = false;
    state.roleList = action.payload;
  },
  [setRoleObj.type]: (state, action) => {
    state.isLoading = false;
    state.isModalLoading = false;
    state.role = action.payload;
  },
  [roleDetailLoaded.type]: (state, action) => {
    state.isLoading = false;
    state.roleDetail = action.payload;
  },
  [dataGridListLoad.type]: (state, action) => {
    state.isLoading = false;
    state.DagGridList = action.payload;
  },
});

export default AnsibleRolesReducer;
