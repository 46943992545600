import { createAction, createReducer } from '@reduxjs/toolkit';

export interface GroupReducer {
  groupList: any[];
  subGroupList: any[];
  hostList: any[];
  group: any;
  host: any;
  isLoading: boolean;
  isRequestSuccessful: boolean;
  groupCount: number;
  isModalLoading: boolean;
  subGroupCount: number;
  hostListCount: number;
}
const InitialState: GroupReducer = {
  groupList: [],
  subGroupList: [],
  hostList: [],
  group: {},
  host: {},
  isLoading: false,
  isRequestSuccessful: false,
  isModalLoading: false,
  groupCount: 0,
  subGroupCount: 0,
  hostListCount: 0,
};
// actions
export const setGroupLoading = createAction<boolean>('group/setGroupLoading');
export const groupListLoaded = createAction<any>('group/groupListLoaded');
export const subGroupListLoaded = createAction<any>('group/subGroupListLoaded');
export const hostListLoaded = createAction<any>('group/hostListLoaded');
export const setGroupObject = createAction<any>('group/setGroupObject');
export const setHostObject = createAction<any>('group/setHostObject');
export const setRequestSuccessful = createAction<any>('group/setRequestSuccessful');
export const setExistingGroupsModalLoading = createAction<boolean>('group/setOrgModalLoading');

const AnsibleGroupReducer = createReducer(InitialState, {
  [setGroupLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setExistingGroupsModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [groupListLoaded.type]: (state, action) => {
    state.isLoading = false;
    state.groupList = action.payload.results;
    state.groupCount = action.payload.count;
    state.isRequestSuccessful = false;
  },
  [subGroupListLoaded.type]: (state, action) => {
    state.isLoading = false;
    state.subGroupList = action.payload.results;
    state.subGroupCount = action.payload.count;
    state.isRequestSuccessful = false;
  },
  [hostListLoaded.type]: (state, action) => {
    state.isLoading = false;
    state.hostList = action.payload.results;
    state.hostListCount = action.payload.count;
    state.isRequestSuccessful = false;
  },
  [setGroupObject.type]: (state, action) => {
    state.group = action.payload;
    state.isModalLoading = false;
    state.isLoading = false;
  },
  [setHostObject.type]: (state, action) => {
    state.host = action.payload;
    state.isModalLoading = false;
    state.isLoading = false;
  },
});

export default AnsibleGroupReducer;
