import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleNotification {
  notificationDetails: any[];
  isLoading: boolean;
  notificationCount: number;
  isModalLoading: boolean;
  notification: any;
  notificationList: any[];
  isRequestSuccessful: boolean;
}
const InitialState: AnsibleNotification = {
  notificationDetails: [],
  isLoading: false,
  isModalLoading: false,
  notification: {},
  notificationList: [],
  notificationCount: 0,
  isRequestSuccessful: false,
};
// actions
export const setNotificationLoading = createAction<boolean>('notification/setNotificationLoading');
export const loadNotificationDetails = createAction<any>('notification/loadNotificationDetails');
export const setNotificationModalLoading = createAction<boolean>('notification/setNotificationModalLoading');
export const notificationListLoaded = createAction<any>('notification/notificationListLoaded');
export const setNotificationObject = createAction<any>('notification/setNotificationObject');
export const setRequestSuccessful = createAction<any>('notification/setRequestSuccessful');

const AnsibleNotificationReducer = createReducer(InitialState, {
  [setNotificationLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [loadNotificationDetails.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setNotificationModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setNotificationObject.type]: (state, action) => {
    state.notification = action.payload;
    state.isModalLoading = false;
    state.isLoading = false;
  },
  [notificationListLoaded.type]: (state, action) => {
    state.notificationList = action.payload.results;
    state.notificationCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
});

export default AnsibleNotificationReducer;
