import React from 'react';
import { Redirect } from 'react-router-dom';
import { SafeDynamicImport } from 'app/core/components/DynamicImports/SafeDynamicImport';
import { config } from 'app/core/config';
import { RouteDescriptor } from 'app/core/navigation/types';

const ansibleRoutes = [
  {
    path: '/ansible',
    // eslint-disable-next-line react/display-name
    component: () => <Redirect to="/ansible/Dashboard" />,
  },
  {
    path: '/ansible/visualizer',
    // eslint-disable-next-line react/display-name
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/visualizer/Visualizer')
    ),
  },
  {
    path: '/ansible/LaunchWorkflow',
    // eslint-disable-next-line react/display-name
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/template/LaunchWorkFlow')
    ),
  },
  {
    path: '/ansible/Dashboard',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/dashboard/Dashboard')
    ),
  },
  {
    path: '/ansible/organization/OrganizationList',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/organization/OrganizationList')
    ),
  },
  {
    path: '/ansible/organization/OrganizationDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/organization/OrganizationDetail')
    ),
  },
  {
    path: '/ansible/organization/OrganizationUser',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/organization/OrganizationUser')
    ),
  },
  {
    path: '/ansible/organization/OrganizationPermission',
    component: SafeDynamicImport(
      () =>
        import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/organization/OrganizationPermission')
    ),
  },
  {
    path: '/ansible/organization/OrganizationNotification',
    component: SafeDynamicImport(
      () =>
        import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/organization/OrganizationNotification')
    ),
  },
  {
    path: '/ansible/user/userList',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/user/UserList')
    ),
  },
  {
    path: '/ansible/user/UserDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/user/UserDetail')
    ),
  },
  {
    path: '/ansible/user/userOrganization',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/user/UserOrganization')
    ),
  },
  {
    path: '/ansible/user/userTeam',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/user/UserTeam')
    ),
  },
  {
    path: '/ansible/user/userPermission',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/user/UserPermission')
    ),
  },
  {
    path: '/ansible/team/teamList',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/team/TeamList')
    ),
  },
  {
    path: '/ansible/team/teamDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/team/TeamDetail')
    ),
  },
  {
    path: '/ansible/team/teamUsers',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/team/TeamUsers')
    ),
  },
  {
    path: '/ansible/team/teamPermission',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/team/TeamPermission')
    ),
  },
  {
    path: '/ansible/inventory/inventoryList',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/InventoryList')
    ),
  },
  {
    path: '/ansible/inventory/inventoryPermission',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/InventoryPermission')
    ),
  },
  {
    path: '/ansible/inventory/inventoryGroup',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/group/GroupList')
    ),
  },
  {
    path: '/ansible/inventory/create/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/group/GroupForm')
    ),
  },
  {
    path: '/ansible/inventory/ExecuteCommand/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/group/ExecuteCommand')
    ),
  },
  {
    path: '/ansible/inventory/SubGroupListForm/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/group/SubGroupListForm')
    ),
  },
  {
    path: '/ansible/inventory/inventoryHost',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/host/HostList')
    ),
  },
  {
    path: '/ansible/inventory/host/subHost/create/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/group/host/HostForm')
    ),
  },
  {
    path: '/ansible/inventory/host/group/create/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/host/group/GroupForm')
    ),
  },
  {
    path: '/ansible/inventory/inventorySource',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/source/SourceList')
    ),
  },
  {
    path: '/ansible/inventory/inventoryDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/InventoryDetail')
    ),
  },
  {
    path: '/ansible/inventory/InventoryForm/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/InventoryForm')
    ),
  },
  {
    path: '/ansible/inventory/SmartInventoryForm/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/SmartInventoryForm')
    ),
  },
  {
    path: '/ansible/credential/CredentialList',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/credential/CredentialList')
    ),
  },

  {
    path: '/ansible/credential/CredentialDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/credential/CredentialDetail')
    ),
  },
  {
    path: '/ansible/credential/CredentialPermission',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/credential/CredentialPermission')
    ),
  },
  {
    path: '/ansible/inventaryScript/InventoryScriptList',
    component: SafeDynamicImport(
      () =>
        import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventaryScript/InventoryScriptList')
    ),
  },
  {
    path: '/ansible/inventoryScript/InventoryScriptDetail/:id',
    component: SafeDynamicImport(
      () =>
        import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventaryScript/InventoryScriptDetail')
    ),
  },

  {
    path: '/ansible/inventory/group/groupDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/group/GroupDetail')
    ),
  },
  {
    path: '/ansible/inventory/group/subGroupDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/group/SubGroupDetail')
    ),
  },
  {
    path: '/ansible/inventory/inventoryGroup/groups/nested_groups',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/group/SubGroupList')
    ),
  },
  {
    path: '/ansible/inventory/inventoryGroup/host',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/group/host/HostList')
    ),
  },
  {
    path: '/ansible/inventory/inventoryGroup/host/HostExecuteCommand',
    component: SafeDynamicImport(
      () =>
        import(
          /* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/group/host/HostExecuteCommand'
        )
    ),
  },
  {
    path: '/ansible/inventory/inventoryGroup/job',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/CompleteJobs')
    ),
  },
  {
    path: '/ansible/inventory/host/hostDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/host/HostDetail')
    ),
  },
  {
    path: '/ansible/inventory/host/hostGroup',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/host/group/GroupList')
    ),
  },
  {
    path: '/ansible/inventory/host/hostGroupExecuteCommand',
    component: SafeDynamicImport(
      () =>
        import(
          /* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/host/group/GroupExecuteCommand'
        )
    ),
  },
  {
    path: '/ansible/inventory/host/job',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/host/HostCompletedJobs')
    ),
  },
  {
    path: '/ansible/inventory/host/fact',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/host/HostFacts')
    ),
  },
  {
    path: '/ansible/inventory/source/sourceDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/source/SourceDetail')
    ),
  },
  {
    path: '/ansible/inventory/source/schedule',
    component: SafeDynamicImport(
      () =>
        import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/source/Schedule/ScheduleList')
    ),
  },
  {
    path: '/ansible/inventory/source/create/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/source/SourceForm')
    ),
  },
  {
    path: '/ansible/project/ProjectList',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/project/ProjectList')
    ),
  },
  {
    path: '/ansible/project/create/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/project/ProjectForm')
    ),
  },
  {
    path: '/ansible/project/ProjectDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/project/ProjectDetail')
    ),
  },
  {
    path: '/ansible/project/ProjectPermission',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/project/ProjectPermission')
    ),
  },
  {
    path: '/ansible/project/ProjectNotification',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/project/ProjectNotification')
    ),
  },
  {
    path: '/ansible/project/ProjectSchedule',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/project/ProjectSchedule')
    ),
  },
  {
    path: '/ansible/project/ProjectScheduleDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/project/ProjectScheduleDetail')
    ),
  },
  {
    path: '/ansible/project/ProjectJobTemplates',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/project/ProjectJobTemplates')
    ),
  },
  {
    path: '/ansible/template/TemplateSurvey',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/template/TemplateSurvey')
    ),
  },
  {
    path: '/ansible/template/TemplateList',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/template/TemplateList')
    ),
  },
  {
    path: '/ansible/template/TemplateDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/template/TemplateDetail')
    ),
  },
  {
    path: '/ansible/template/TemplatePermission',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/template/TemplatePermission')
    ),
  },
  {
    path: '/ansible/template/TemplateNotification',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/template/TemplateNotification')
    ),
  },
  {
    path: '/ansible/template/TemplateSchedule',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/template/TemplateSchedule')
    ),
  },
  {
    path: '/ansible/template/Schedule/create/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/template/TemplateScheduleForm')
    ),
  },
  {
    path: '/ansible/template/TemplateScheduleDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/template/TemplateScheduleDetail')
    ),
  },
  {
    path: '/ansible/template/TemplateCompletedJobs',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/template/TemplateCompletedJobs')
    ),
  },
  {
    path: '/ansible/template/JobTemplateForm/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/template/JobTemplateForm')
    ),
  },
  {
    path: '/ansible/template/TemplateForm/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/template/TemplateForm')
    ),
  },
  {
    path: '/ansible/template/TemplateAddSurvey',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/template/TemplateAddSurvey')
    ),
  },
  {
    path: '/ansible/credentialType/CredentialTypeList',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/credentialType/CredentialTypeList')
    ),
  },
  {
    path: '/ansible/credentialType/CredentialTypeForm/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/credentialType/CredentialTypeForm')
    ),
  },
  {
    path: '/ansible/credential/CredentialForm/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/credential/CredentialForm')
    ),
  },
  {
    path: '/ansible/credentialType/CredentialTypeDetail/:id',
    component: SafeDynamicImport(
      () =>
        import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/credentialType/CredentialTypeDetail')
    ),
  },
  {
    path: '/ansible/instanceGroup/instanceList',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/instanceGroups/InstanceGroupList')
    ),
  },
  {
    path: '/ansible/templateFlow',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/template/TemplateFlow')
    ),
  },
  {
    path: '/ansible/instanceGroup/containerForm',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/instanceGroups/ContainerGroupForm')
    ),
  },
  {
    path: '/ansible/instanceGroup/instanceDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/instanceGroups/InstanceGroupDetail')
    ),
  },
  {
    path: '/ansible/instanceGroup/instanceGroupInstances',
    component: SafeDynamicImport(
      () =>
        import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/instanceGroups/instances/InstancesList')
    ),
  },
  {
    path: '/ansible/instanceGroup/InstancesDetail/:id',
    component: SafeDynamicImport(
      () =>
        import(
          /* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/instanceGroups/instances/InstanceDetail'
        )
    ),
  },
  {
    path: '/ansible/instanceGroup/jobs',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/instanceGroups/job/JobList')
    ),
  },
  {
    path: '/ansible/application/ApplicationList',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/applicaiton/ApplicationList')
    ),
  },
  {
    path: '/ansible/applicaiton/ApplicationDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/applicaiton/ApplicationDetail')
    ),
  },
  {
    path: '/ansible/applicaiton/ApplicationToken',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/applicaiton/ApplicationToken')
    ),
  },
  {
    path: '/ansible/notification/NotificationList',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/notification/NotificationList')
    ),
  },
  {
    path: '/ansible/notification/NotificationDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/notification/NotificationDetail')
    ),
  },
  {
    path: '/ansible/notification/NotificationForm/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/notification/NotificationForm')
    ),
  },
  {
    path: '/ansible/managementJob/managementJobs',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/managementJob/ManagementJobList')
    ),
  },
  {
    path: '/ansible/managementJob/notification/:id',
    component: SafeDynamicImport(
      () =>
        import(
          /* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/managementJob/notification/NotificationList'
        )
    ),
  },
  {
    path: '/ansible/managementJob/schedule/:id',
    component: SafeDynamicImport(
      () =>
        import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/managementJob/schedule/ScheduleList')
    ),
  },
  {
    path: '/ansible/jobs',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/job/JobList')
    ),
  },
  {
    path: '/ansible/schedules',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/schedule/ScheduleList')
    ),
  },
  {
    path: '/ansible/myView',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/myView/ViewList')
    ),
  },
  {
    path: '/ansible/managementJob/scheduleDetail/:id',
    component: SafeDynamicImport(
      () =>
        import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/managementJob/schedule/ScheduleDetail')
    ),
  },
  {
    path: '/ansible/managementJob/createSchedule/:id?',
    component: SafeDynamicImport(
      () =>
        import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/managementJob/schedule/ScheduleForm')
    ),
  },
  {
    path: '/ansible/project/createSchedule/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/project/ProjectScduleForm')
    ),
  },
  {
    path: '/ansible/setting/Setting',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/setting/Setting')
    ),
  },
  {
    path: '/ansible/inventory/HostList',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/HostList')
    ),
  },
  {
    path: '/ansible/job/JobDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/job/JobDetail')
    ),
  },
  {
    path: '/ansible/inventory/source/InventorySourceNotification',
    component: SafeDynamicImport(
      () =>
        import(
          /* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/source/InventorySourceNotification'
        )
    ),
  },
  {
    path: '/ansible/inventory/source/schedule/create/:id?',
    component: SafeDynamicImport(
      () =>
        import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/source/Schedule/ScheduleForm')
    ),
  },
  {
    path: '/ansible/inventory/host/HostForm/:id?',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/host/HostForm')
    ),
  },
  {
    path: '/ansible/inventory/source/Schedule/ScheduleDetail/:id',
    component: SafeDynamicImport(
      () =>
        import(
          /* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/source/Schedule/ScheduleDetail'
        )
    ),
  },
  {
    path: '/ansible/setting/authentication/AuthenticationAzureAdForm',
    component: SafeDynamicImport(
      () =>
        import(
          /* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/setting/authentication/AuthenticationAzureAdForm'
        )
    ),
  },
  {
    path: '/ansible/setting/authentication/AuthenticationGithub',
    component: SafeDynamicImport(
      () =>
        import(
          /* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/setting/authentication/AuthenticationGithub'
        )
    ),
  },
  {
    path: '/ansible/setting/authentication/AuthenticationGoogleAuth',
    component: SafeDynamicImport(
      () =>
        import(
          /* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/setting/authentication/AuthenticationGoogleOauth'
        )
    ),
  },
  {
    path: '/ansible/setting/authentication/AuthenticationLdAp',
    component: SafeDynamicImport(
      () =>
        import(
          /* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/setting/authentication/AuthenticationLdAp'
        )
    ),
  },
  {
    path: '/ansible/setting/authentication/AuthenticationRadius',
    component: SafeDynamicImport(
      () =>
        import(
          /* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/setting/authentication/AuthenticationRadius'
        )
    ),
  },
  {
    path: '/ansible/setting/authentication/AuthenticationSaMl',
    component: SafeDynamicImport(
      () =>
        import(
          /* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/setting/authentication/AuthenticationSaMl'
        )
    ),
  },
  {
    path: '/ansible/setting/authentication/AuthenticationTaCaS',
    component: SafeDynamicImport(
      () =>
        import(
          /* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/setting/authentication/AuthenticationTaCaS'
        )
    ),
  },
  {
    path: '/ansible/setting/jobs/JobsForm',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/setting/jobs/JobsForm')
    ),
  },
  {
    path: '/ansible/setting/system/SystemMisc',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/setting/system/SystemMisc')
    ),
  },
  {
    path: '/ansible/setting/system/SystemLogging',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/setting/system/SystemLogging')
    ),
  },
  {
    path: '/ansible/setting/system/SystemActivityStream',
    component: SafeDynamicImport(
      () =>
        import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/setting/system/SystemActivityStream')
    ),
  },
  {
    path: '/ansible/setting/userInterface/SettingUserInterfaceForm',
    component: SafeDynamicImport(
      () =>
        import(
          /* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/setting/userInterface/SettingUserInterfaceForm'
        )
    ),
  },
  {
    path: '/ansible/setting/license/SettingLicenseForm',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/setting/license/SettingLicenseForm')
    ),
  },
  {
    path: '/ansible/inventory/source/Schedule/ScheduleForm',
    component: SafeDynamicImport(
      () =>
        import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/inventory/source/Schedule/ScheduleForm')
    ),
  },
  {
    path: '/ansible/CustomNodes',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AnsibleDashboard" */ 'app/features/ansible/CustomeNodes')
    ),
  },
];
export function getAnsibleRoutes(cfg = config): RouteDescriptor[] {
  return ansibleRoutes;
  /*  if (cfg.alertingEnabled || cfg.unifiedAlertingEnabled) {
    return ansibleRoutes;
  }

  return ansibleRoutes.map((route) => ({
    ...route,
    component: SafeDynamicImport(
      () => import(/!* webpackChunkName: "Alerting feature toggle page"*!/ 'app/features/alerting/FeatureTogglePage')
    ),
  }));*/
}
