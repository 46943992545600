import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleAction {
  isLoading: boolean;
  isModalLoading: boolean;
  isRequestSuccessful: boolean;
  ActionList: any;
}
const InitialState: AnsibleAction = {
  isLoading: false,
  isModalLoading: false,
  isRequestSuccessful: false,
  ActionList: [],
};
// actions
export const setDataCollectorLoading = createAction<boolean>('DataCollector/setJobLoading');
export const setModalLoading = createAction<boolean>('DataCollector/setModalLoading');
export const setRequestSuccessful = createAction<boolean>('DataCollector/setRequestSuccessful');
export const DCActionListLoaded = createAction<any>('DCActionListLoaded/DCActionListLoaded');

const DCActionsReducer = createReducer(InitialState, {
  [setDataCollectorLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [DCActionListLoaded.type]: (state, action) => {
    state.isLoading = false;
    state.ActionList = action.payload;
  },
});

export default DCActionsReducer;
