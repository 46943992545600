import {
  loadTemplateDetails,
  templateListLoaded,
  setTemplateLoading,
  setTemplateObject,
  templatePermissionList,
  templateNotificactionList,
  templateScheduleList,
  TemplateCompletedJob,
  setWorkTemplateLoading,
  workTemplateListLoaded,
  setRequestSuccessful,
  setLaunchModalData,
  setJobTemplateModalLoading,
  workFlowPendingListLoaded,
  setWorkFlowPendingListLoading,
  setScheduleObject,
  templateSurveyList,
  setSurveyObject,
  setLaunchWorkFlowTemp,
} from './reducer';
import { createErrorNotification, createSuccessNotification } from '../../../../core/copy/appNotification';
import { notifyApp } from '../../../../core/reducers/appNotification';
import { IJobTemplates } from '../../interfaces/IJobTemplates';
import httpService, { ansibleBaseURL, ansibleHeaders } from '../../apiCall/httpService';
import moment from 'moment';
import { locationService } from '../../../../../../packages/grafana-runtime';
import { IWorkflowJobTemplates } from '../../interfaces/IWorkflowJobTemplates';
import { dateTime } from '../../../../../../packages/grafana-data';
import { setMyViewLoading, setMyViewWorkTemplateLoading } from '../../myView/state/reducer';
const url = `${ansibleBaseURL}job_templates`;
const workflowUrl = `${ansibleBaseURL}workflow_job_templates`;
export const ansibleTemplateId = 'ansibleTemplateId';
export const ansibleTemplateSchudleId = 'ansibleTemplateSchudleId';
export const ansibleTemplateType = 'ansibleTemplateType';
export const ansibleWorkFlowTemplateId = 'ansibleWorkFlowTemplateId';

export const loadTemplates = (pageSize: number, page = 1, search?: string, orderBy?: string) => {
  return async (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    let params = {
      page_size: pageSize,
      page: page,
      search: search,
      order_by: orderBy,
    };
    await httpService
      ._get(url, { params: params })
      .then((response: any) => {
        if (response && response.status === 200 && response.data.results) {
          let final = response.data;
          for (let obj of response.data.results) {
            obj.job_type = obj.summary_fields.type;
            obj.jobActivity = obj.summary_fields.recent_jobs;
          }
          dispatch(templateListLoaded(final));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
  };
};
export const loadWorkFlowApprovals = (pageSize: number, page = 1, search?: string, orderBy = 'created') => {
  return async (dispatch: any) => {
    dispatch(setWorkFlowPendingListLoading(true));
    let params = {
      page_size: pageSize,
      page: page,
      search: search,
      order_by: orderBy,
      status: 'pending',
    };
    await httpService
      ._get(`${ansibleBaseURL}workflow_approvals`, { params: params })
      .then((response: any) => {
        console.log('workFlow pending approvals', response);
        if (response && response.status === 200 && response.data.results) {
          let final = response.data;
          dispatch(workFlowPendingListLoaded(final));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
  };
};
export const loadInventories = (pageSize = 8, page?: number, search?: string, orderBy?: string) => {
  let params = {
    page_size: pageSize,
    page: page,
    search: search,
    order_by: orderBy,
  };
  return async (dispatch: any) => {
    let result: any = [];
    await httpService
      ._get(`${ansibleBaseURL}inventories`, { params: params })
      .then((response: any) => {
        if (response && response.status === 200 && response.data.results) {
          result = response.data.results.map((c: any) => {
            return { label: c.name, value: c.id };
          });
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
    return result;
  };
};
export const loadProjects = (pageSize = 8, page?: number, search?: string, orderBy?: string) => {
  let params = {
    page_size: pageSize,
    page: page,
    search: search,
    order_by: orderBy,
  };
  return async (dispatch: any) => {
    let result: any = [];
    await httpService
      ._get(`${ansibleBaseURL}projects`, { params: params })
      .then((response: any) => {
        if (response && response.status === 200 && response.data.results) {
          result = response.data.results.map((c: any) => {
            return { label: c.name, value: c.id };
          });
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
    return result;
  };
};
export const loadLabels = (pageSize = 8, page?: number, search?: string, orderBy?: string) => {
  let params = {
    page_size: pageSize,
    page: page,
    search: search,
    order_by: orderBy,
  };
  return async (dispatch: any) => {
    let result: any = [];
    await httpService
      ._get(`${ansibleBaseURL}labels`, { params: params })
      .then((response: any) => {
        if (response && response.status === 200 && response.data.results) {
          result = response.data.results.map((c: any) => {
            return { label: c.name, value: c.id };
          });
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
    return result;
  };
};
export const loadInstanceGroup = (pageSize = 8, page?: number, search?: string, orderBy?: string) => {
  let params = {
    page_size: pageSize,
    page: page,
    search: search,
    order_by: orderBy,
  };
  return async (dispatch: any) => {
    let result: any = [];
    await httpService
      ._get(`${ansibleBaseURL}instance_groups`, { params: params })
      .then((response: any) => {
        if (response && response.status === 200 && response.data.results) {
          result = response.data.results.map((c: any) => {
            return { label: c.name, value: c.id };
          });
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
    return result;
  };
};
export const loadPlaybook = (id: number, pageSize = 8, page?: number, search?: string, orderBy?: string) => {
  let params = {
    page_size: pageSize,
    page: page,
    search: search,
    order_by: orderBy,
  };
  return async (dispatch: any) => {
    let result: any = [];
    await httpService
      ._get(`${ansibleBaseURL}projects/${id}/playbooks`, { params: params })
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          result = response.data.map((c: any) => {
            return { label: c, value: c };
          });
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
    return result;
  };
};
export const loadCredentialTypes = (pageSize = 8, page?: number, search?: string, orderBy?: string) => {
  let params = {
    page_size: pageSize,
    page: page,
    search: search,
    order_by: orderBy,
  };
  return async (dispatch: any) => {
    let result: any = [];
    await httpService
      ._get(`${ansibleBaseURL}credential_types`, { params: params })
      .then((response: any) => {
        if (response && response.status === 200 && response.data.results) {
          result = response.data.results.map((c: any) => {
            return { label: c.name, value: c.id };
          });
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
    return result;
  };
};
export const loadCredentials = (pageSize = 8, page?: number, search?: string, orderBy?: string) => {
  let params = {
    page_size: pageSize,
    page: page,
    search: search,
    order_by: orderBy,
  };
  return async (dispatch: any) => {
    let result: any = [];
    await httpService
      ._get(`${ansibleBaseURL}credentials`, { params: params })
      .then((response: any) => {
        if (response && response.status === 200 && response.data.results) {
          result = response.data.results.map((c: any) => {
            return { label: c.name, value: c.id };
          });
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
    return result;
  };
};
export const loadWebhookCreds = (type: any, pageSize = 8, page?: number, search?: string, orderBy?: string) => {
  let params = {
    credential_type__namespace: type,
    page_size: pageSize,
    page: page,
    search: search,
    order_by: orderBy,
  };
  return async (dispatch: any) => {
    let result: any = [];
    await httpService
      ._get(`${ansibleBaseURL}credentials`, { params: params })
      .then((response: any) => {
        if (response && response.status === 200 && response.data.results) {
          result = response.data.results.map((c: any) => {
            return { label: c.name, value: c.id };
          });
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
    return result;
  };
};
export const loadWorkTemplates = (pageSize: number, page = 1, search?: string, orderBy?: string) => {
  return async (dispatch: any) => {
    dispatch(setWorkTemplateLoading(true));
    let params = {
      page_size: pageSize,
      page: page,
      search: search,
      order_by: orderBy,
    };
    await httpService
      ._get(`${ansibleBaseURL}workflow_job_templates`, { params: params })
      .then((response: any) => {
        if (response && response.status === 200 && response.data.results) {
          let workfinal = response.data;
          for (let obj of response.data.results) {
            obj.job_type = obj.summary_fields.type;
          }
          dispatch(workTemplateListLoaded(workfinal));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
  };
};
export const loadSchedule = (pageSize: number, page = 1, search?: string, orderBy?: string) => {
  return async (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    let params = {
      page_size: pageSize,
      page: page,
      search: search,
      order_by: orderBy,
    };
    let type = localStorage.getItem(ansibleTemplateType);
    let id = localStorage.getItem(ansibleTemplateId);
    if (type !== 'workflowTemplate') {
      await httpService
        ._get(`${url}/${id}/schedules`, { params: params })
        .then((response: any) => {
          console.log(response);
          if (response && response.status === 200 && response.data.results) {
            let final = response.data;
            final.results.forEach((i: any) => {
              i.dtstart = moment(i.dtstart).format('MMMM Do YYYY, h:mm:ss a');
              i.dtend = moment(i.dtend).format('MMMM Do YYYY, h:mm:ss a');
            });

            dispatch(templateScheduleList(response.data));
          } else {
            dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
          }
        })
        .catch((error) => {
          dispatch(notifyApp(createErrorNotification('Error', error)));
        });
    } else {
      await httpService
        ._get(`${ansibleBaseURL}workflow_job_templates/${id}/schedules`, { params: params })
        .then((response: any) => {
          console.log(response);
          if (response && response.status === 200 && response.data.results) {
            let final = response.data;
            final.results.forEach((i: any) => {
              i.dtstart = moment(i.dtstart).format('MMMM Do YYYY, h:mm:ss a');
              i.dtend = moment(i.dtend).format('MMMM Do YYYY, h:mm:ss a');
            });

            dispatch(templateScheduleList(response.data));
          } else {
            dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
          }
        })
        .catch((error) => {
          dispatch(notifyApp(createErrorNotification('Error', error)));
        });
    }
  };
};
export const templateDetail = () => {
  return (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    dispatch(loadTemplateDetails([{ id: 1, name: 'user Name' }]));
  };
};
export const loadTemplateNotification = (pageSize: number, page = 1, search?: string, orderBy = 'name') => {
  return async (dispatch: any) => {
    let finalUrl = localStorage.getItem(ansibleTemplateType) === 'workflowTemplate' ? workflowUrl : url;
    dispatch(setTemplateLoading(true));
    let params = {
      page_size: pageSize,
      page: page,
      search: search,
      order_by: orderBy,
    };

    let id = localStorage.getItem(ansibleTemplateId);
    await httpService
      ._get(`${ansibleBaseURL}notification_templates`, { params: params })
      .then(async (response: any) => {
        if (response && response.status === 200 && response.data.results) {
          let notifications = response.data;

          //notification_templates_error
          await httpService
            ._get(`${finalUrl}/${id}/notification_templates_error`, { params: params })
            .then((response: any) => {
              if (response && response.status === 200 && response.data.results) {
                notifications = {
                  ...notifications,
                  results: notifications.results.map((i: any) => {
                    let temp = response.data.results.some((find: any) => find.id === i.id)
                      ? { ...i, isError: true }
                      : { ...i, isError: false };
                    return temp;
                  }),
                };
              } else {
                dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
              }
            })
            .catch((error) => {
              dispatch(notifyApp(createErrorNotification('Error', error)));
            });

          //notification_templates_started
          await httpService
            ._get(`${finalUrl}/${id}/notification_templates_started`, { params: params })
            .then((response: any) => {
              if (response && response.status === 200 && response.data.results) {
                notifications = {
                  ...notifications,
                  results: notifications.results.map((i: any) => {
                    let temp = response.data.results.some((find: any) => find.id === i.id)
                      ? { ...i, isStarted: true }
                      : { ...i, isStarted: false };
                    return temp;
                  }),
                };
              } else {
                dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
              }
            })
            .catch((error) => {
              dispatch(notifyApp(createErrorNotification('Error', error)));
            });

          //notification_templates_success
          await httpService
            ._get(`${finalUrl}/${id}/notification_templates_success`, { params: params })
            .then((response: any) => {
              if (response && response.status === 200 && response.data.results) {
                notifications = {
                  ...notifications,
                  results: notifications.results.map((i: any) => {
                    let temp = response.data.results.some((find: any) => find.id === i.id)
                      ? { ...i, isSuccess: true }
                      : { ...i, isSuccess: false };
                    return temp;
                  }),
                };
              } else {
                dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
              }
            })
            .catch((error) => {
              dispatch(notifyApp(createErrorNotification('Error', error)));
            });
          dispatch(templateNotificactionList(notifications));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
  };
};
export const templatePermissionData = (pageSize: number, page = 1, search?: string, orderBy?: string) => {
  return async (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    let params = {
      page_size: pageSize,
      page: page,
      search: search,
      order_by: orderBy,
    };
    let id = localStorage.getItem(ansibleTemplateId);
    let type = localStorage.getItem(ansibleTemplateType);
    if (type !== 'workflowTemplate') {
      await httpService
        ._get(`${url}/${id}/access_list`, { params: params })
        .then((response: any) => {
          console.log('permission ', response);
          if (response && response.status === 200 && response.data.results) {
            dispatch(templatePermissionList(response.data));
          } else {
            dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
          }
        })
        .catch((error) => {
          dispatch(notifyApp(createErrorNotification('Error', error)));
        });
    } else {
      await httpService
        ._get(`${ansibleBaseURL}workflow_job_templates/${id}/access_list`, { params: params })
        .then((response: any) => {
          console.log('permission ', response);
          if (response && response.status === 200 && response.data.results) {
            dispatch(templatePermissionList(response.data));
          } else {
            dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
          }
        })
        .catch((error) => {
          dispatch(notifyApp(createErrorNotification('Error', error)));
        });
    }
  };
};
export const templateCompletedJobList = (pageSize: number, page = 1, search?: string, orderBy = 'name') => {
  return async (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    let params = {
      page_size: pageSize,
      page: page,
      search: search,
      order_by: orderBy,
    };
    let id = localStorage.getItem(ansibleTemplateId);
    // @ts-ignore
    params = { ...params, job__job_template: id };
    await httpService
      ._get(`${ansibleBaseURL}unified_jobs`, { params: params })
      .then((response: any) => {
        console.log(response);
        if (response && response.status === 200 && response.data.results) {
          let final = response.data;
          final.results.forEach((i: any) => {
            i.date = moment(i.created).format('MMMM Do YYYY');
            i.time = moment(i.created).format('h:mm:ss ');
          });
          dispatch(TemplateCompletedJob(final));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
  };
};

export const getWorkFlow = (pageSize: number, page = 1, search?: string, orderBy = 'name') => {
  return (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    dispatch(
      setLaunchWorkFlowTemp({
        also_run_this: 'works',
        run_after_loop: 'run_after_loop',
        runme_2: 'runme_2',
        this_will_skip: 'this_will_skip',
        runme_1: 'runme_1',
        runme_0: 'runme_0',
      })
    );
  };
};

export const getTemplate = (id: number, isEdit = false) => {
  return async (dispatch: any) => {
    if (isEdit) {
      dispatch(setJobTemplateModalLoading(true));
    } else {
      dispatch(setTemplateLoading(true));
    }
    await httpService
      ._get(`${ansibleBaseURL}job_templates/${id}`, { headers: ansibleHeaders })
      .then(async (response: any) => {
        if (response && response.status === 200 && response.data) {
          let tempObject = response.data;
          if (response.data.job_tags) {
            let hosts = response.data.job_tags.split(',').map((i: string) => {
              return { label: i, value: i };
            });
            tempObject = {
              ...tempObject,
              job_tags: hosts,
            };
          }
          if (response.data.skip_tags) {
            let hosts = response.data.skip_tags.split(',').map((i: string) => {
              return { label: i, value: i };
            });
            tempObject = {
              ...tempObject,
              skip_tags: hosts,
            };
          }
          if (response.data.host_config_key) {
            tempObject = {
              ...tempObject,
              allow_callbacks: true,
            };
          }
          if (response.data.job_type) {
            let type: any;
            if (response.data.job_type === 'run') {
              type = { label: 'Run', value: 'run' };
            } else {
              type = { label: 'Check', value: 'check' };
            }
            tempObject = {
              ...tempObject,
              job_type: type,
            };
          }
          if (response.data.verbosity) {
            let obj = null;
            if (response.data.verbosity === 0) {
              obj = { label: '0 (Normal)', value: 0 };
            } else if (response.data.verbosity === 1) {
              obj = { label: '1 (Verbose)', value: 1 };
            } else if (response.data.verbosity === 2) {
              obj = { label: '2 (More Verbose)', value: 2 };
            } else if (response.data.verbosity === 3) {
              obj = { label: '3 (Debug)', value: 3 };
            } else if (response.data.verbosity === 4) {
              obj = { label: '4 (Connection Debug)', value: 4 };
            } else if (response.data.verbosity === 5) {
              obj = { label: '5 (WinRM Debug)', value: 5 };
            }
            tempObject = {
              ...tempObject,
              verbosity: obj,
            };
          }
          if (response.data.webhook_service) {
            tempObject = {
              ...tempObject,
              webhook_service: { label: response.data.webhook_service, value: response.data.webhook_service },
            };
          }
          if (response.data.summary_fields.labels.results) {
            response.data.summary_fields.labels.results.map((c: any) => {
              tempObject = {
                ...tempObject,
                labels: { label: c.name, value: c.id },
              };
            });
          }
          tempObject = {
            ...tempObject,
            launchBy: response.data.summary_fields.created_by.username,
            instanceGroup: response.data.summary_fields.instance_group?.name,
            projects: response.data.summary_fields.project?.name,
            credential: response.data.summary_fields.credentials?.map((i: any) => i.name).join(','),
            credentials: response.data.summary_fields.credentials?.map((i: any) => {
              return { label: i.name, value: i.id };
            }),
            //play_count: response.data.playbook_counts.play_count,
            //task_count: response.data.playbook_counts.task_count,
            sourceWorkflow: response.data.summary_fields.source_workflow_job?.name,
            project: {
              label: response.data.summary_fields.project.name,
              value: response.data.summary_fields.project.id,
            },
            inventory: {
              label: response.data.summary_fields.inventory.name,
              value: response.data.summary_fields.inventory.id,
            },
            instanceGroups: response.data.summary_fields.instance_group?.map((c: any) => {
              return { label: c.name, value: c.id };
            }),
            playbook: { label: response.data.playbook, value: response.data.playbook },
            webhook_service: { label: response.data.webhook_service, value: response.data.webhook_service },
            enable_webhook: response.data.webhook_service ? true : false,
          };
          await httpService
            ._get(`${url}/${id}/instance_groups`, { headers: ansibleHeaders })
            .then(async (response: any) => {
              if (response && response.status === 200 && response.data.results) {
                response.data.results.map((c: any) => {
                  tempObject = {
                    ...tempObject,
                    instanceGroupsForForm: { label: c.name, value: c.id },
                    instanceGroups: [c.name].join(' '),
                  };
                });
                dispatch(setTemplateObject(tempObject));
              } else {
                dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
              }
            })
            .catch((error) => {
              dispatch(notifyApp(createErrorNotification('Error', error)));
            });
          dispatch(setTemplateObject(tempObject));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
  };
};
export const getWorkflowTemplate = (id: number, isEdit = false) => {
  return async (dispatch: any) => {
    if (isEdit) {
      dispatch(setJobTemplateModalLoading(true));
    } else {
      dispatch(setTemplateLoading(true));
    }
    await httpService
      ._get(`${ansibleBaseURL}workflow_job_templates/${id}`, { headers: ansibleHeaders })
      .then(async (response: any) => {
        console.log('job workflow', response);
        if (response && response.status === 200 && response.data) {
          console.log('job workflow', response);
          let tempObject = response.data;
          if (response.data?.summary_fields?.labels?.results) {
            response.data?.summary_fields?.labels?.results?.map((c: any) => {
              tempObject = {
                ...tempObject,
                labels: { label: c.name, value: c.id },
              };
            });
          }
          tempObject = {
            ...tempObject,
            launchBy: response.data?.summary_fields?.created_by?.username,
            //play_count: response.data.playbook_counts.play_count,
            //task_count: response.data.playbook_counts.task_count,
            sourceWorkflow: response.data?.summary_fields?.source_workflow_job?.name,
          };
          if (tempObject.organization) {
            tempObject.organization = {
              label: tempObject?.summary_fields?.organization?.name,
              value: tempObject?.summary_fields?.organization?.id,
            };
          }
          if (tempObject.inventory) {
            tempObject.inventory = {
              label: tempObject?.summary_fields?.inventory?.name,
              value: tempObject?.summary_fields?.inventory?.id,
            };
          }
          if (tempObject.webhook_service !== '') {
            tempObject = {
              ...tempObject,
              webhook_service:
                tempObject.webhook_service === 'github'
                  ? {
                      label: 'GitHub',
                      value: tempObject.webhook_service,
                    }
                  : tempObject.webhook_service === 'gitlab'
                  ? {
                      label: 'GitLab',
                      value: tempObject.webhook_service,
                    }
                  : (tempObject.webhook_service = {
                      label: '',
                      value: '',
                    }),
              enable_webhook: true,
            };
          }
          console.log('tempobject', tempObject);
          dispatch(setTemplateObject(tempObject));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
  };
};
export const getTemplateSchedule = (id: number, isEdit = false) => {
  return async (dispatch: any) => {
    if (isEdit) {
      dispatch(setJobTemplateModalLoading(true));
    } else {
      dispatch(setTemplateLoading(true));
    }
    let sheduleId = localStorage.getItem(ansibleTemplateSchudleId);
    await httpService
      ._get(`${ansibleBaseURL}schedules/${sheduleId}`, { headers: ansibleHeaders })
      .then(async (response: any) => {
        if (response && response.status === 200) {
          console.log(response);
          dispatch(setTemplateObject(response.data));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
  };
};
export const addTemplate = (formData: IJobTemplates) => {
  let jobTagArray: any[] = [];
  if (formData.job_tags) {
    let host: any = formData.job_tags;
    host.map((c: any) => {
      jobTagArray.push(`${c.value}`);
    });
  }
  let skipTagArray: any[] = [];
  if (formData.skip_tags) {
    let host: any = formData.skip_tags;
    host.map((c: any) => {
      skipTagArray.push(`${c.value}`);
    });
  }
  formData.job_tags = formData.job_tags ? jobTagArray.join(',') : '';
  formData.skip_tags = formData.job_tags ? skipTagArray.join(',') : '';
  formData.webhook_service = formData.webhook_service?.value;
  formData.verbosity = formData.verbosity?.value;
  formData.project = formData?.project?.value;
  formData.playbook = formData?.playbook?.value;
  formData.job_type = formData?.job_type?.value;
  formData.inventory = formData?.inventory?.value;
  console.log('Temp Data', formData);
  return async (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    await httpService
      ._post(url, formData, { headers: ansibleHeaders })
      .then(async (response: any) => {
        console.log('response', response);
        if (response && response.status === 200 && response.data.id) {
          const ins_group = formData?.instanceGroupsForForm;
          const label = formData?.labels;
          const credential = formData?.credentials;
          //adding instance group
          if (ins_group) {
            ins_group.map(async (c: any) => {
              await httpService
                ._post(
                  url + `/${response.data.id}/instance_groups`,
                  { id: c.value, name: c.label },
                  { headers: ansibleHeaders }
                )
                .then((response: any) => {
                  if (response && response.status === 200 && response.data.detail) {
                    dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
                  }
                })
                .catch((error) => {
                  dispatch(notifyApp(createErrorNotification('Error', error)));
                  dispatch(setTemplateLoading(false));
                });
            });
          }
          if (label) {
            label.map(async (c: any) => {
              let obj = null;
              if (c.value === c.label) {
                obj = { name: c.label, organization: response.data.organization };
              } else {
                obj = { associate: true, id: c.value };
              }
              await httpService
                ._post(url + `/${response.data.id}/labels`, obj, { headers: ansibleHeaders })
                .then((response: any) => {
                  if (response && response.status === 200 && response.data.detail) {
                    dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
                  }
                })
                .catch((error) => {
                  dispatch(notifyApp(createErrorNotification('Error', error)));
                  dispatch(setTemplateLoading(false));
                });
            });
          }
          if (credential) {
            credential.map(async (c: any) => {
              await httpService
                ._post(url + `/${response.data.id}/credentials`, { id: c.value }, { headers: ansibleHeaders })
                .then((response: any) => {
                  if (response && response.status === 200 && response.data.detail) {
                    dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
                  }
                })
                .catch((error) => {
                  dispatch(notifyApp(createErrorNotification('Error', error)));
                  dispatch(setTemplateLoading(false));
                });
            });
          }
          // dispatching final actions
          dispatch(setRequestSuccessful(true));
          dispatch(notifyApp(createSuccessNotification('Template Added successfully', '')));
        } else {
          let keys = Object.keys(response.data);
          keys.forEach((i: string) => {
            response.data[i].forEach((er: any) => {
              dispatch(notifyApp(createErrorNotification('Error', er)));
            });
          });
        }
        dispatch(setTemplateLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setTemplateLoading(false));
      });
  };
};
export const editTemplate = (formData: IJobTemplates) => {
  let jobTagArray: any[] = [];
  if (formData.job_tags) {
    let host: any = formData.job_tags;
    host.map((c: any) => {
      jobTagArray.push(`${c.value}`);
    });
  }
  let skipTagArray: any[] = [];
  if (formData.skip_tags) {
    let host: any = formData.skip_tags;
    host.map((c: any) => {
      skipTagArray.push(`${c.value}`);
    });
  }
  formData.job_tags = formData.job_tags ? jobTagArray.join(',') : '';
  formData.skip_tags = formData.skip_tags ? skipTagArray.join(',') : '';
  formData.webhook_service = formData.webhook_service?.value;
  formData.verbosity = formData.verbosity?.value;
  formData.project = formData?.project?.value;
  formData.playbook = formData?.playbook?.value;
  formData.job_type = formData?.job_type?.value;
  formData.inventory = formData?.inventory?.value;
  console.log('Temp Data', formData);
  return async (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    await httpService
      ._put(url + `/${formData.id}`, formData, { headers: ansibleHeaders })
      .then(async (response: any) => {
        console.log('response', response);
        if (response && response.status === 200 && response.data.id) {
          const ins_group = formData.instanceGroupsForForm;
          const label = formData.labels;
          const credential = formData.credentials;
          //adding instance group
          if (ins_group?.length > 0) {
            ins_group.map(async (c: any) => {
              await httpService
                ._post(
                  url + `/${response.data.id}/instance_groups`,
                  { id: c.value, name: c.label },
                  { headers: ansibleHeaders }
                )
                .then((response: any) => {
                  if (response && response.status === 200 && response.data.detail) {
                    dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
                  }
                })
                .catch((error) => {
                  dispatch(notifyApp(createErrorNotification('Error', error)));
                  dispatch(setTemplateLoading(false));
                });
            });
          }
          if (label?.length > 0) {
            label.map(async (c: any) => {
              await httpService
                ._post(url + `/${response.data.id}/labels`, { id: c.value, name: c.label }, { headers: ansibleHeaders })
                .then((response: any) => {
                  if (response && response.status === 200 && response.data.detail) {
                    dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
                  }
                })
                .catch((error) => {
                  dispatch(notifyApp(createErrorNotification('Error', error)));
                  dispatch(setTemplateLoading(false));
                });
            });
          }
          if (credential?.length > 0) {
            credential.map(async (c: any) => {
              await httpService
                ._post(url + `/${response.data.id}/credentials`, { id: c.value }, { headers: ansibleHeaders })
                .then((response: any) => {
                  if (response && response.status === 200 && response.data.detail) {
                    dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
                  }
                })
                .catch((error) => {
                  dispatch(notifyApp(createErrorNotification('Error', error)));
                  dispatch(setTemplateLoading(false));
                });
            });
          }
          // dispatching final actions
          dispatch(setRequestSuccessful(true));
          dispatch(notifyApp(createSuccessNotification('Template updated successfully', '')));
        } else {
          let keys = Object.keys(response.data);
          keys.forEach((i: string) => {
            response.data[i].forEach((er: any) => {
              dispatch(notifyApp(createErrorNotification('Error', er)));
            });
          });
        }
        dispatch(setTemplateLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setTemplateLoading(false));
      });
  };
};
export const deleteTemplate = (id: number, name: string) => {
  return async (dispatch: any) => {
    dispatch(setJobTemplateModalLoading(true));
    await httpService
      ._delete(url + `/${id}`, { headers: ansibleHeaders })
      .then((response: any) => {
        if (response && response.status === 200 && !response.data.id) {
          dispatch(setRequestSuccessful(true));
          dispatch(notifyApp(createSuccessNotification(`'${name}' deleted successfully`, '')));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
        dispatch(setJobTemplateModalLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setJobTemplateModalLoading(false));
      });
  };
};
export const addWorkflowTemplate = (formData: IWorkflowJobTemplates) => {
  formData.inventory = formData?.inventory?.value;
  formData.organization = formData?.organization?.value;
  console.log('Temp Data', formData);
  return async (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    await httpService
      ._post(workflowUrl, formData, { headers: ansibleHeaders })
      .then(async (response: any) => {
        console.log('response', response);
        if (response && response.status === 200 && response.data.id) {
          const label = formData?.labels;
          //adding instance group
          if (label) {
            label.map(async (c: any) => {
              let obj = null;
              if (c.value === c.label) {
                obj = { name: c.label, organization: response.data.organization };
              } else {
                obj = { associate: true, id: c.value };
              }
              await httpService
                ._post(workflowUrl + `/${response.data.id}/labels`, obj, { headers: ansibleHeaders })
                .then((response: any) => {
                  if (response && response.status === 200 && response.data.detail) {
                    dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
                  }
                })
                .catch((error) => {
                  dispatch(notifyApp(createErrorNotification('Error', error)));
                  dispatch(setTemplateLoading(false));
                });
            });
          }
          // dispatching final actions
          dispatch(setRequestSuccessful(true));
          dispatch(notifyApp(createSuccessNotification('Template added successfully', '')));
        } else {
          let keys = Object.keys(response.data);
          keys.forEach((i: string) => {
            response.data[i].forEach((er: any) => {
              dispatch(notifyApp(createErrorNotification('Error', er)));
            });
          });
        }
        dispatch(setTemplateLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setTemplateLoading(false));
      });
  };
};
export const editWorkflowTemplate = (formData: IWorkflowJobTemplates) => {
  formData.inventory = formData?.inventory?.value;
  formData.organization = formData?.organization?.value;
  formData.webhook_service = formData?.webhook_service?.value;
  console.log('Temp Data', formData);
  return async (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    await httpService
      ._put(workflowUrl + `/${formData.id}`, formData, { headers: ansibleHeaders })
      .then(async (response: any) => {
        console.log('response', response);
        if (response && response.status === 200 && response.data.id) {
          const label = formData?.labels;
          //adding instance group
          if (label?.length > 0) {
            label.map(async (c: any) => {
              let obj = null;
              if (c.value === c.label) {
                obj = { name: c.label, organization: formData.organization };
              } else {
                obj = { associate: true, id: c.value };
              }
              await httpService
                ._post(workflowUrl + `/${formData.id}/labels`, obj, { headers: ansibleHeaders })
                .then((response: any) => {
                  if (response && response.status === 200 && response.data.detail) {
                    dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
                  }
                })
                .catch((error) => {
                  dispatch(notifyApp(createErrorNotification('Error', error)));
                  dispatch(setTemplateLoading(false));
                });
            });
          }
          // dispatching final actions
          dispatch(setRequestSuccessful(true));
          dispatch(notifyApp(createSuccessNotification('Template updates successfully', '')));
        } else {
          let keys = Object.keys(response.data);
          keys.forEach((i: string) => {
            response.data[i].forEach((er: any) => {
              dispatch(notifyApp(createErrorNotification('Error', er)));
            });
          });
        }
        dispatch(setTemplateLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setTemplateLoading(false));
      });
  };
};
export const deleteWorkflowTemplate = (id: number, name: string) => {
  return async (dispatch: any) => {
    dispatch(setJobTemplateModalLoading(true));
    await httpService
      ._delete(workflowUrl + `/${id}`, { headers: ansibleHeaders })
      .then((response: any) => {
        if (response && response.status === 200 && !response.data.id) {
          dispatch(setRequestSuccessful(true));
          dispatch(notifyApp(createSuccessNotification(`'${name}' deleted successfully`, '')));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
        dispatch(setJobTemplateModalLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setJobTemplateModalLoading(false));
      });
  };
};
export const addTemplateSchedule = (formData: IJobTemplates) => {
  console.log('addTemplate Schedule middleware', formData);
  return (dispatch: any) => {
    //if needed then do something and then make api call to add organization
    dispatch(notifyApp(createSuccessNotification('Template Schedule added successfully', '')));
  };
};
export const editTemplateSchedule = (formData: IJobTemplates) => {
  console.log('editTemplate middleware', formData);
  return (dispatch: any) => {
    //if needed then do something and then make api call to edit organization
    dispatch(notifyApp(createSuccessNotification('Template Schedule updated successfully', '')));
  };
};
export const addSurvey = (formData: any, varName: string) => {
  let id = localStorage.getItem(ansibleTemplateId);
  return async (dispatch: any, getState: any) => {
    dispatch(setJobTemplateModalLoading(true));
    let templates = [...getState().ansibleTemplate.templateSurvey];
    if (templates && templates.length > 0 && varName !== '') {
      templates = templates.filter((i) => i.variable !== varName);
    }
    let data = { ...formData, spec: [...formData.spec, ...templates] };
    console.log('data', data);
    await httpService
      ._post(`${url}/${id}/survey_spec`, data, { headers: ansibleHeaders })
      .then((response: any) => {
        if (response && response.status === 200 && response.data === '') {
          //const { id: orgId } = response.data;
          dispatch(setRequestSuccessful(true));
          dispatch(notifyApp(createSuccessNotification('Survey added successfully', '')));
        } else {
          let keys = Object.keys(response.data);
          for (let val of keys) {
            dispatch(notifyApp(createErrorNotification('Error', response.data[val])));
          }
        }
        dispatch(setJobTemplateModalLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setJobTemplateModalLoading(false));
      });
  };
};

export const editSurvey = (formData: any) => {
  console.log('check ', formData);
  return (dispatch: any, getState: any) => {
    dispatch(setSurveyObject(formData));
  };
};

export const copyTemplateData = (data: any) => {
  return async (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    let timeNew = moment().format('LTS');
    let dataTime = data.name + '@' + timeNew;

    let postData: any = { name: dataTime };
    await httpService
      ._post(`${url}/${data.id}/copy`, postData, { headers: ansibleHeaders })
      .then(async (response: any) => {
        if (response && response.status === 200) {
          dispatch(setRequestSuccessful(true));
          /*dispatch(notifyApp(createSuccessNotification('Organization added successfully', '')));*/
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
        dispatch(setTemplateLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setTemplateLoading(false));
      });
  };
};
export const copyWorkflowTemplateData = (data: any) => {
  return async (dispatch: any) => {
    dispatch(setWorkTemplateLoading(true));
    let timeNew = moment().format('LTS');
    let dataTime = data.name + '@' + timeNew;

    let postData: any = { name: dataTime };
    await httpService
      ._post(`${ansibleBaseURL}workflow_job_templates/${data.id}/copy`, postData, { headers: ansibleHeaders })
      .then(async (response: any) => {
        if (response && response.status === 200) {
          dispatch(setRequestSuccessful(true));
          /*dispatch(notifyApp(createSuccessNotification('Organization added successfully', '')));*/
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
        dispatch(setWorkTemplateLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setWorkTemplateLoading(false));
      });
  };
};
export const deleteJobTemplate = (id: number, name: string) => {
  return async (dispatch: any) => {
    dispatch(setJobTemplateModalLoading(true));
    await httpService
      ._delete(`${ansibleBaseURL}users/${id}`, { headers: ansibleHeaders })
      .then((response: any) => {
        if (response && response.status === 200 && !response.data.detail) {
          dispatch(setRequestSuccessful(true));

          dispatch(notifyApp(createSuccessNotification(`'${name}' deleted successfully`, '')));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
        dispatch(setJobTemplateModalLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setJobTemplateModalLoading(false));
      });
  };
};
export const startNotification = (data: any) => {
  return async (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    let finalUrl = localStorage.getItem(ansibleTemplateType) === 'workflowTemplate' ? workflowUrl : url;
    let projId = localStorage.getItem(ansibleTemplateId);
    let postData: any = { id: data.id };
    if (data.isDisassociate) {
      postData = { ...postData, disassociate: true };
    }
    await httpService
      ._post(`${finalUrl}/${projId}/notification_templates_started`, postData, { headers: ansibleHeaders })
      .then(async (response: any) => {
        if (response && response.status === 200) {
          console.log('something', response);
          dispatch(setRequestSuccessful(true));
          /*dispatch(notifyApp(createSuccessNotification('Organization added successfully', '')));*/
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
        dispatch(setTemplateLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setTemplateLoading(false));
      });
  };
};
export const successNotification = (data: any) => {
  return async (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    let finalUrl = localStorage.getItem(ansibleTemplateType) === 'workflowTemplate' ? workflowUrl : url;
    let projId = localStorage.getItem(ansibleTemplateId);
    let postData: any = { id: data.id };
    if (data.isDisassociate) {
      postData = { ...postData, disassociate: true };
    }
    await httpService
      ._post(`${finalUrl}/${projId}/notification_templates_success`, postData, { headers: ansibleHeaders })
      .then(async (response: any) => {
        if (response && response.status === 200) {
          dispatch(setRequestSuccessful(true));
          /*dispatch(notifyApp(createSuccessNotification('Organization added successfully', '')));*/
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
        dispatch(setTemplateLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setTemplateLoading(false));
      });
  };
};
export const failureNotification = (data: any) => {
  return async (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    let finalUrl = localStorage.getItem(ansibleTemplateType) === 'workflowTemplate' ? workflowUrl : url;
    let projId = localStorage.getItem(ansibleTemplateId);
    let postData: any = { id: data.id };
    if (data.isDisassociate) {
      postData = { ...postData, disassociate: true };
    }
    await httpService
      ._post(`${finalUrl}/${projId}/notification_templates_error`, postData, { headers: ansibleHeaders })
      .then(async (response: any) => {
        if (response && response.status === 200) {
          dispatch(setRequestSuccessful(true));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
        dispatch(setTemplateLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setTemplateLoading(false));
      });
  };
};
export const launchJobTemplate = (id: any, isWorkFLow: boolean) => {
  return async (dispatch: any) => {
    let finalUrl = isWorkFLow ? workflowUrl : url;
    isWorkFLow ? dispatch(setWorkTemplateLoading(true)) : dispatch(setTemplateLoading(true));
    await httpService
      ._get(`${finalUrl}/${id}/launch`, { headers: ansibleHeaders })
      .then(async (response: any) => {
        console.log('launch response', response);
        if (response && response.status === 200 && response.data) {
          const { data } = response;
          if (
            !data.ask_inventory_on_launch &&
            !data.ask_limit_on_launch &&
            !data.ask_scm_branch_on_launch &&
            !data.ask_variables_on_launch &&
            data.can_start_without_user_input &&
            isWorkFLow
          ) {
            dispatch(notifyApp(createSuccessNotification(`Launched without user input`, '')));
            dispatch(launchAfterJobTemp(data.workflow_job_template_data.id, {}, isWorkFLow));
          } else {
            let final = {
              isStartWithInput: data.ask_variables_on_launch,
              askInventoryOnLaunch: data.ask_inventory_on_launch,
              askVerbosityOnLaunch: data.ask_verbosity_on_launch,
              askLimitOnLaunch: data.ask_limit_on_launch,
              isWorkFlow: isWorkFLow,
              obj: data.defaults,
              name: isWorkFLow ? data.workflow_job_template_data.name : data.job_template_data.name,
              id: isWorkFLow ? data.workflow_job_template_data.id : data.job_template_data.id,
            };
            dispatch(setLaunchModalData(final));
            isWorkFLow ? dispatch(setWorkTemplateLoading(false)) : dispatch(setTemplateLoading(false));
            dispatch(setMyViewWorkTemplateLoading(false));
            dispatch(setMyViewLoading(false));
          }
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
          isWorkFLow ? dispatch(setWorkTemplateLoading(false)) : dispatch(setTemplateLoading(false));
          dispatch(setMyViewWorkTemplateLoading(false));
          dispatch(setMyViewLoading(false));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        isWorkFLow ? dispatch(setWorkTemplateLoading(false)) : dispatch(setTemplateLoading(false));
        dispatch(setMyViewWorkTemplateLoading(false));
        dispatch(setMyViewLoading(false));
      });
  };
};
export const approveWorkFlowJob = (id: any, lastUrl: string, data: any) => {
  return async (dispatch: any) => {
    dispatch(setWorkFlowPendingListLoading(true));
    await httpService
      ._post(`${ansibleBaseURL}workflow_approvals/${id}/${lastUrl}`, data, { headers: ansibleHeaders })
      .then(async (response: any) => {
        console.log('approved', response);
        if (response && response.status === 200 && !response.data.detail) {
          dispatch(loadWorkFlowApprovals(8, 1));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
          dispatch(setWorkFlowPendingListLoading(false));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setWorkFlowPendingListLoading(false));
      });
  };
};
export const launchAfterJobTemp = (id: any, data: any, isWorkFlow: boolean) => {
  return async (dispatch: any) => {
    let finalUrl = isWorkFlow ? workflowUrl : url;
    dispatch(setJobTemplateModalLoading(true));
    await httpService
      ._post(`${finalUrl}/${id}/launch`, data, { headers: ansibleHeaders })
      .then(async (response: any) => {
        console.log('launched', response);
        if (response && response.status === 200 && response.data.id) {
          let data = response.data;
          dispatch(setLaunchModalData(null));
          locationService.push(`/ansible/job/JobDetail/${data.id}?url=${data.url}`);
        } else {
          let keys = Object.keys(response.data);
          keys.forEach((i: string) => {
            response.data[i].forEach((er: any) => {
              dispatch(notifyApp(createErrorNotification('Error', er)));
            });
          });
        }
        dispatch(setJobTemplateModalLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setJobTemplateModalLoading(false));
      });
  };
};
export const deleteTemplateSchedule = (id: number, name: string) => {
  return async (dispatch: any) => {
    dispatch(setJobTemplateModalLoading(true));
    await httpService
      ._delete(`${ansibleBaseURL}schedules/${id}`, { headers: ansibleHeaders })
      .then((response: any) => {
        if (response && response.status === 200 && !response.data.detail) {
          dispatch(setRequestSuccessful(true));
          dispatch(notifyApp(createSuccessNotification(`'${name}' deleted successfully`, '')));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
        dispatch(setJobTemplateModalLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setJobTemplateModalLoading(false));
      });
  };
};
export const loadOrganizations = (pageSize = 8, page?: number, search?: string, orderBy?: string) => {
  let params = {
    page_size: pageSize,
    page: page,
    search: search,
    order_by: orderBy,
  };
  return async (dispatch: any) => {
    let result: any = [];
    await httpService
      ._get(`${ansibleBaseURL}organizations`, { params: params })
      .then((response: any) => {
        if (response && response.status === 200 && response.data.results) {
          result = response.data.results.map((c: any) => {
            return { label: c.name, value: c.id };
          });
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
    return result;
  };
};
export const loadInventoriesForWorkFlow = (pageSize = 8, page?: number, search?: string, orderBy?: string) => {
  let params = {
    page_size: pageSize,
    page: page,
    search: search,
    order_by: orderBy,
  };
  return async (dispatch: any) => {
    let result: any = [];
    await httpService
      ._get(`${ansibleBaseURL}inventories`, { params: params })
      .then((response: any) => {
        console.log('us', response);
        if (response && response.status === 200 && response.data.results) {
          result = response.data.results.map((c: any) => {
            return { label: c.name, value: c.id };
          });
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
    return result;
  };
};
export const addPermission = (formData: any) => {
  console.log('Add Permission', formData);
  let object = Object.keys(formData);
  return async (dispatch: any) => {
    dispatch(setJobTemplateModalLoading(true));
    for (let obj of object) {
      if (obj === 'newUser') {
        console.log('User Call', obj);
        formData[obj].map((c: any) => {
          c.role.map(async (d: any) => {
            let userId = { id: d.value };
            await httpService
              ._post(`${ansibleBaseURL}users/${c.id}/roles`, userId, { headers: ansibleHeaders })
              .then(async (response: any) => {
                if (response && response.status === 200) {
                  // dispatching final actions
                  dispatch(setJobTemplateModalLoading(false));
                  dispatch(setRequestSuccessful(true));
                  dispatch(notifyApp(createSuccessNotification('Roles are assigned successfully', '')));
                } else {
                  dispatch(setJobTemplateModalLoading(false));
                  let keys = Object.keys(response.data);
                  keys.forEach((i: string) => {
                    response.data[i].forEach((er: any) => {
                      dispatch(notifyApp(createErrorNotification('Error', er)));
                    });
                  });
                }
              })
              .catch((error) => {
                dispatch(notifyApp(createErrorNotification('Error', error)));
                dispatch(setJobTemplateModalLoading(false));
              });
          });
        });
      } else if (obj === 'newTeam') {
        console.log('Team Call', obj);
        formData[obj].map((c: any) => {
          c.role.map(async (d: any) => {
            let teamId = { id: d.value };
            await httpService
              ._post(`${ansibleBaseURL}teams/${c.id}/roles`, teamId, { headers: ansibleHeaders })
              .then(async (response: any) => {
                if (response && response.status === 200) {
                  // dispatching final actions
                  dispatch(notifyApp(createSuccessNotification('Roles are assigned successfully', '')));
                } else {
                  dispatch(setJobTemplateModalLoading(false));
                  let keys = Object.keys(response.data);
                  keys.forEach((i: string) => {
                    response.data[i].forEach((er: any) => {
                      dispatch(notifyApp(createErrorNotification('Error', er)));
                    });
                  });
                }
              })
              .catch((error) => {
                dispatch(notifyApp(createErrorNotification('Error', error)));
                dispatch(setJobTemplateModalLoading(false));
              });
          });
        });
      }
    }
  };
};
export const loadLocalTimeZone = (pageSize = 8, page?: number, search?: string, orderBy?: string) => {
  let params = {
    page_size: pageSize,
    page: page,
    search: search,
    order_by: orderBy,
  };
  return async (dispatch: any) => {
    let result: any = [];
    await httpService
      ._get(`${ansibleBaseURL}schedules/zoneinfo`, { params: params })
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          console.log('Response', response);
          result = response.data.map((c: any) => {
            return { label: c.name, value: c.name };
          });
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
    return result;
  };
};
export const addSchedule = (formData: any) => {
  let id = localStorage.getItem(ansibleTemplateId);
  let type = localStorage.getItem(ansibleTemplateType);
  let urlType: string;
  if (type === 'workflowTemplate') {
    urlType = `${ansibleBaseURL}workflow_job_templates`;
  } else if (type === 'Template') {
    urlType = `${ansibleBaseURL}job_templates`;
  }
  return async (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    await httpService
      ._post(`${urlType}/${id}/schedules`, formData, { headers: ansibleHeaders })
      .then(async (response: any) => {
        console.log('projectForm', response);
        if (response && response.status === 200 && response.data.id > 0) {
          // dispatching final actions
          dispatch(setRequestSuccessful(true));
          dispatch(notifyApp(createSuccessNotification('Schedule added successfully', '')));
        } else {
          dispatch(setTemplateLoading(false));
          let keys = Object.keys(response.data);
          keys.forEach((i: string) => {
            response.data[i].forEach((er: any) => {
              dispatch(notifyApp(createErrorNotification('Error', er)));
            });
          });
        }
      })
      .catch((error) => {
        dispatch(setTemplateLoading(false));
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
  };
};
export const getSchedule = (id: number, isEdit = false) => {
  return async (dispatch: any) => {
    if (isEdit) {
      dispatch(setJobTemplateModalLoading(true));
    } else {
      dispatch(setTemplateLoading(true));
    }
    await httpService
      ._get(`${ansibleBaseURL}schedules/${id}`, { headers: ansibleHeaders })
      .then(async (response: any) => {
        if (response && response.status === 200) {
          console.log('', response);
          let object = response.data;
          let date;
          let time;
          let dates;
          let endDate;
          let endTime;
          let freq;
          let interval;
          let count;
          let countValue;
          let ends;
          let checkArray;
          let filterdays = [];
          let days: any[] = [];
          let byDay;
          let BYSETPOS;
          let untilString;
          let byMonthCount;
          let byYearCount;
          let byMonth;
          let month;
          let onDay = false;
          let onYearDay = false;
          if (object.rrule) {
            dates = object.rrule.split(':')[1].split(' ')[0];
            date = moment(dates).format('YYYY-MM-DD HH:m:s').split(' ')[0];
            time = dateTime(dates);
            freq = object.rrule.split(' ')[1].split('=')[1].split(';')[0];
            interval = object.rrule.split(' ')[1].split(';')[1].split('=')[1];
            checkArray = object.rrule.split(' ')[1].split(';');
            if (freq === 'MINUTELY') {
              ends = { label: 'Never', value: 1 };
              if (checkArray.length === 3) {
                count = object.rrule.split(' ')[1].split(';')[2].split('=')[0];
                if (count === 'COUNT') {
                  countValue = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                  ends = { label: 'After', value: 2 };
                } else if (count === 'UNTIL') {
                  untilString = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                  endDate = moment(untilString).format('YYYY-MM-DD HH:m:s').split(' ')[0];
                  endTime = dateTime(untilString);
                  ends = { label: 'On Day', value: 3 };
                }
              }
              object = {
                ...object,
                startDate: date,
                startTime: time,
                repeatFrequency: { label: 'Minute', value: freq },
                every: Number(interval),
                occurrence: Number(countValue),
                end: ends,
                endDate: endDate,
                endTime: endTime,
              };
            } else if (freq === 'HOURLY') {
              ends = { label: 'Never', value: 1 };
              if (checkArray.length === 3) {
                count = object.rrule.split(' ')[1].split(';')[2].split('=')[0];
                if (count === 'COUNT') {
                  countValue = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                  ends = { label: 'After', value: 2 };
                } else if (count === 'UNTIL') {
                  untilString = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                  endDate = moment(untilString).format('YYYY-MM-DD HH:m:s').split(' ')[0];
                  endTime = dateTime(untilString);
                  ends = { label: 'On Day', value: 3 };
                }
              }
              object = {
                ...object,
                startDate: date,
                startTime: time,
                repeatFrequency: { label: 'Hour', value: freq },
                every: Number(interval),
                occurrence: Number(countValue),
                end: ends,
                endDate: endDate,
                endTime: endTime,
              };
            } else if (freq === 'DAILY') {
              ends = { label: 'Never', value: 1 };
              if (checkArray.length === 3) {
                count = object.rrule.split(' ')[1].split(';')[2].split('=')[0];
                if (count === 'COUNT') {
                  countValue = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                  ends = { label: 'After', value: 2 };
                } else if (count === 'UNTIL') {
                  untilString = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                  endDate = moment(untilString).format('YYYY-MM-DD HH:m:s').split(' ')[0];
                  endTime = dateTime(untilString);
                  ends = { label: 'On Day', value: 3 };
                }
              }
              object = {
                ...object,
                startDate: date,
                startTime: time,
                repeatFrequency: { label: 'Day', value: freq },
                every: Number(interval),
                occurrence: countValue ? Number(countValue) : null,
                end: ends,
                endDate: endDate,
                endTime: endTime,
              };
            } else if (freq === 'WEEKLY') {
              if (checkArray.length === 3) {
                filterdays = object.rrule.split(' ')[1].split(';')[2].split('=')[1].split(',');
                filterdays.map((c: any) => {
                  let day;
                  if (c === 'MO') {
                    day = { label: 'Mon', value: c };
                  } else if (c === 'TU') {
                    day = { label: 'Tue', value: c };
                  } else if (c === 'WE') {
                    day = { label: 'Wed', value: c };
                  } else if (c === 'TH') {
                    day = { label: 'Thu', value: c };
                  } else if (c === 'FR') {
                    day = { label: 'Fri', value: c };
                  } else if (c === 'SA') {
                    day = { label: 'Sat', value: c };
                  } else if (c === 'SU') {
                    day = { label: 'Sun', value: c };
                  }
                  days.push(day);
                });
                ends = { label: 'Never', value: 1 };
              }
              if (checkArray.length === 4) {
                filterdays = object.rrule.split(' ')[1].split(';')[2].split('=')[1].split(',');
                filterdays.map((c: any) => {
                  let day;
                  if (c === 'MO') {
                    day = { label: 'Mon', value: c };
                  } else if (c === 'TU') {
                    day = { label: 'Tue', value: c };
                  } else if (c === 'WE') {
                    day = { label: 'Wed', value: c };
                  } else if (c === 'TH') {
                    day = { label: 'Thu', value: c };
                  } else if (c === 'FR') {
                    day = { label: 'Fri', value: c };
                  } else if (c === 'SA') {
                    day = { label: 'Sat', value: c };
                  } else if (c === 'SU') {
                    day = { label: 'Sun', value: c };
                  }
                  days.push(day);
                });
                count = object.rrule.split(' ')[1].split(';')[3].split('=')[0];
                if (count === 'COUNT') {
                  countValue = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                  ends = { label: 'After', value: 2 };
                } else if (count === 'UNTIL') {
                  untilString = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                  endDate = moment(untilString).format('YYYY-MM-DD HH:m:s').split(' ')[0];
                  endTime = dateTime(untilString);
                  ends = { label: 'On Day', value: 3 };
                }
              }
              object = {
                ...object,
                startDate: date,
                startTime: time,
                repeatFrequency: { label: 'Day', value: freq },
                every: Number(interval),
                onDays: days,
                occurrence: countValue ? Number(countValue) : null,
                endDate: endDate,
                endTime: endTime,
                end: ends,
              };
            } else if (freq === 'MONTHLY') {
              if (checkArray.length === 3) {
                byMonth = object.rrule.split(' ')[1].split(';')[2].split('=')[0];
                if (byMonth === 'BYMONTHDAY') {
                  byMonthCount = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                  onDay = false;
                }
                ends = { label: 'Never', value: 1 };
              } else if (checkArray.length === 4) {
                byMonth = object.rrule.split(' ')[1].split(';')[2].split('=')[0];
                if (byMonth === 'BYDAY') {
                  byDay = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                  if (byDay === 'MO') {
                    byDay = { label: 'Monday', value: byDay };
                  } else if (byDay === 'TU') {
                    byDay = { label: 'Tuesday', value: byDay };
                  } else if (byDay === 'WE') {
                    byDay = { label: 'Wednesday', value: byDay };
                  } else if (byDay === 'TH') {
                    byDay = { label: 'Thursday', value: byDay };
                  } else if (byDay === 'FR') {
                    byDay = { label: 'Friday', value: byDay };
                  } else if (byDay === 'SA') {
                    byDay = { label: 'Saturday', value: byDay };
                  } else if (byDay === 'SU') {
                    byDay = { label: 'Sunday', value: byDay };
                  } else if (byDay === 'MO,TU,WE,TH,FR,SA,SU') {
                    let array = byDay.split(',');
                    byDay = { label: 'Day', value: array };
                  } else if (byDay === 'MO,TU,WE,TH,FR') {
                    let array = byDay.split(',');
                    byDay = { label: 'Weekday', value: array };
                  } else if (byDay === 'SA,SU') {
                    let array = byDay.split(',');
                    byDay = { label: 'Weekend Day', value: array };
                  }
                  BYSETPOS = object.rrule.split(' ')[1].split(';')[3].split('=')[1];
                  if (BYSETPOS === '1') {
                    BYSETPOS = { label: 'First', value: 1 };
                  } else if (BYSETPOS === '2') {
                    BYSETPOS = { label: 'Second', value: 2 };
                  } else if (BYSETPOS === '3') {
                    BYSETPOS = { label: 'Third', value: 3 };
                  } else if (BYSETPOS === '4') {
                    BYSETPOS = { label: 'Fourth', value: 4 };
                  } else if (BYSETPOS === '5') {
                    BYSETPOS = { label: 'Last', value: 5 };
                  }
                  onDay = true;
                  ends = { label: 'Never', value: 1 };
                } else if (byMonth === 'BYMONTHDAY') {
                  byMonthCount = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                  count = object.rrule.split(' ')[1].split(';')[3].split('=')[0];
                  if (count === 'COUNT') {
                    countValue = object.rrule.split(' ')[1].split(';')[3].split('=')[1];
                    ends = { label: 'After', value: 2 };
                  } else if (count === 'UNTIL') {
                    untilString = object.rrule.split(' ')[1].split(';')[3].split('=')[1];
                    endDate = moment(untilString).format('YYYY-MM-DD HH:m:s').split(' ')[0];
                    endTime = dateTime(untilString);
                    ends = { label: 'On Day', value: 3 };
                  }
                  onDay = false;
                }
              } else if (checkArray.length === 5) {
                byDay = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                if (byDay === 'MO') {
                  byDay = { label: 'Monday', value: byDay };
                } else if (byDay === 'TU') {
                  byDay = { label: 'Tuesday', value: byDay };
                } else if (byDay === 'WE') {
                  byDay = { label: 'Wednesday', value: byDay };
                } else if (byDay === 'TH') {
                  byDay = { label: 'Thursday', value: byDay };
                } else if (byDay === 'FR') {
                  byDay = { label: 'Friday', value: byDay };
                } else if (byDay === 'SA') {
                  byDay = { label: 'Saturday', value: byDay };
                } else if (byDay === 'SU') {
                  byDay = { label: 'Sunday', value: byDay };
                } else if (byDay === 'MO,TU,WE,TH,FR,SA,SU') {
                  let array = byDay.split(',');
                  byDay = { label: 'Day', value: array };
                } else if (byDay === 'MO,TU,WE,TH,FR') {
                  let array = byDay.split(',');
                  byDay = { label: 'Weekday', value: array };
                } else if (byDay === 'SA,SU') {
                  let array = byDay.split(',');
                  byDay = { label: 'Weekend Day', value: array };
                }
                BYSETPOS = object.rrule.split(' ')[1].split(';')[3].split('=')[1];
                if (BYSETPOS === '1') {
                  BYSETPOS = { label: 'First', value: 1 };
                } else if (BYSETPOS === '2') {
                  BYSETPOS = { label: 'Second', value: 2 };
                } else if (BYSETPOS === '3') {
                  BYSETPOS = { label: 'Third', value: 3 };
                } else if (BYSETPOS === '4') {
                  BYSETPOS = { label: 'Fourth', value: 4 };
                } else if (BYSETPOS === '5') {
                  BYSETPOS = { label: 'Last', value: 5 };
                }
                count = object.rrule.split(' ')[1].split(';')[4].split('=')[0];
                if (count === 'COUNT') {
                  countValue = object.rrule.split(' ')[1].split(';')[3].split('=')[1];
                  ends = { label: 'After', value: 2 };
                } else if (count === 'UNTIL') {
                  untilString = object.rrule.split(' ')[1].split(';')[3].split('=')[1];
                  endDate = moment(untilString).format('YYYY-MM-DD HH:m:s').split(' ')[0];
                  endTime = dateTime(untilString);
                  ends = { label: 'On Day', value: 3 };
                }
                onDay = true;
              }
              object = {
                ...object,
                startDate: date,
                startTime: time,
                repeatFrequency: { label: 'Month', value: freq },
                every: Number(interval),
                monthDay: byMonthCount,
                onDay: onDay,
                rotation: BYSETPOS,
                selectDays: byDay,
                occurrence: countValue ? Number(countValue) : null,
                endDate: endDate,
                endTime: endTime,
                end: ends,
              };
            } else if (freq === 'YEARLY') {
              if (checkArray.length === 4) {
                month = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                console.log('month', month);
                if (month === '1') {
                  month = { label: 'January', value: 1 };
                } else if (month === '2') {
                  month = { label: 'February', value: 2 };
                } else if (month === '3') {
                  month = { label: 'March', value: 3 };
                } else if (month === '4') {
                  month = { label: 'April', value: 4 };
                } else if (month === '5') {
                  month = { label: 'May', value: 5 };
                } else if (month === '6') {
                  month = { label: 'June', value: 6 };
                } else if (month === '7') {
                  month = { label: 'July', value: 7 };
                } else if (month === '8') {
                  month = { label: 'August', value: 8 };
                } else if (month === '9') {
                  month = { label: 'September', value: 9 };
                } else if (month === '10') {
                  month = { label: 'October', value: 10 };
                } else if (month === '11') {
                  month = { label: 'November', value: 11 };
                } else if (month === '12') {
                  month = { label: 'December', value: 12 };
                }
                byYearCount = object.rrule.split(' ')[1].split(';')[3].split('=')[1];
                onYearDay = false;
              } else if (checkArray.length === 5) {
                count = object.rrule.split(' ')[1].split(';')[4].split('=')[0];
                if (count === 'COUNT') {
                  month = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                  if (month === '1') {
                    month = { label: 'January', value: 1 };
                  } else if (month === '2') {
                    month = { label: 'February', value: 2 };
                  } else if (month === '3') {
                    month = { label: 'March', value: 3 };
                  } else if (month === '4') {
                    month = { label: 'April', value: 4 };
                  } else if (month === '5') {
                    month = { label: 'May', value: 5 };
                  } else if (month === '6') {
                    month = { label: 'June', value: 6 };
                  } else if (month === '7') {
                    month = { label: 'July', value: 7 };
                  } else if (month === '8') {
                    month = { label: 'August', value: 8 };
                  } else if (month === '9') {
                    month = { label: 'September', value: 9 };
                  } else if (month === '10') {
                    month = { label: 'October', value: 10 };
                  } else if (month === '11') {
                    month = { label: 'November', value: 11 };
                  } else if (month === '12') {
                    month = { label: 'December', value: 12 };
                  }
                  byYearCount = object.rrule.split(' ')[1].split(';')[3].split('=')[1];
                  onYearDay = false;
                  countValue = object.rrule.split(' ')[1].split(';')[4].split('=')[1];
                  ends = { label: 'After', value: 2 };
                } else if (count === 'UNTIL') {
                  month = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                  if (month === '1') {
                    month = { label: 'January', value: 1 };
                  } else if (month === '2') {
                    month = { label: 'February', value: 2 };
                  } else if (month === '3') {
                    month = { label: 'March', value: 3 };
                  } else if (month === '4') {
                    month = { label: 'April', value: 4 };
                  } else if (month === '5') {
                    month = { label: 'May', value: 5 };
                  } else if (month === '6') {
                    month = { label: 'June', value: 6 };
                  } else if (month === '7') {
                    month = { label: 'July', value: 7 };
                  } else if (month === '8') {
                    month = { label: 'August', value: 8 };
                  } else if (month === '9') {
                    month = { label: 'September', value: 9 };
                  } else if (month === '10') {
                    month = { label: 'October', value: 10 };
                  } else if (month === '11') {
                    month = { label: 'November', value: 11 };
                  } else if (month === '12') {
                    month = { label: 'December', value: 12 };
                  }
                  byYearCount = object.rrule.split(' ')[1].split(';')[3].split('=')[1];
                  onYearDay = false;
                  untilString = object.rrule.split(' ')[1].split(';')[4].split('=')[1];
                  endDate = moment(untilString).format('YYYY-MM-DD HH:m:s').split(' ')[0];
                  endTime = dateTime(untilString);
                  ends = { label: 'On Day', value: 3 };
                } else if (count === 'BYMONTH') {
                  byDay = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                  if (byDay === 'MO') {
                    byDay = { label: 'Monday', value: byDay };
                  } else if (byDay === 'TU') {
                    byDay = { label: 'Tuesday', value: byDay };
                  } else if (byDay === 'WE') {
                    byDay = { label: 'Wednesday', value: byDay };
                  } else if (byDay === 'TH') {
                    byDay = { label: 'Thursday', value: byDay };
                  } else if (byDay === 'FR') {
                    byDay = { label: 'Friday', value: byDay };
                  } else if (byDay === 'SA') {
                    byDay = { label: 'Saturday', value: byDay };
                  } else if (byDay === 'SU') {
                    byDay = { label: 'Sunday', value: byDay };
                  } else if (byDay === 'MO,TU,WE,TH,FR,SA,SU') {
                    let array = byDay.split(',');
                    byDay = { label: 'Day', value: array };
                  } else if (byDay === 'MO,TU,WE,TH,FR') {
                    let array = byDay.split(',');
                    byDay = { label: 'Weekday', value: array };
                  } else if (byDay === 'SA,SU') {
                    let array = byDay.split(',');
                    byDay = { label: 'Weekend Day', value: array };
                  }
                  BYSETPOS = object.rrule.split(' ')[1].split(';')[3].split('=')[1];
                  if (BYSETPOS === '1') {
                    BYSETPOS = { label: 'First', value: 1 };
                  } else if (BYSETPOS === '2') {
                    BYSETPOS = { label: 'Second', value: 2 };
                  } else if (BYSETPOS === '3') {
                    BYSETPOS = { label: 'Third', value: 3 };
                  } else if (BYSETPOS === '4') {
                    BYSETPOS = { label: 'Fourth', value: 4 };
                  } else if (BYSETPOS === '5') {
                    BYSETPOS = { label: 'Last', value: 5 };
                  }
                  month = object.rrule.split(' ')[1].split(';')[4].split('=')[1];
                  if (month === '1') {
                    month = { label: 'January', value: 1 };
                  } else if (month === '2') {
                    month = { label: 'February', value: 2 };
                  } else if (month === '3') {
                    month = { label: 'March', value: 3 };
                  } else if (month === '4') {
                    month = { label: 'April', value: 4 };
                  } else if (month === '5') {
                    month = { label: 'May', value: 5 };
                  } else if (month === '6') {
                    month = { label: 'June', value: 6 };
                  } else if (month === '7') {
                    month = { label: 'July', value: 7 };
                  } else if (month === '8') {
                    month = { label: 'August', value: 8 };
                  } else if (month === '9') {
                    month = { label: 'September', value: 9 };
                  } else if (month === '10') {
                    month = { label: 'October', value: 10 };
                  } else if (month === '11') {
                    month = { label: 'November', value: 11 };
                  } else if (month === '12') {
                    month = { label: 'December', value: 12 };
                  }
                  onYearDay = true;
                  ends = { label: 'Never', value: 1 };
                }
              } else if (checkArray.length === 6) {
                byDay = object.rrule.split(' ')[1].split(';')[2].split('=')[1];
                if (byDay === 'MO') {
                  byDay = { label: 'Monday', value: byDay };
                } else if (byDay === 'TU') {
                  byDay = { label: 'Tuesday', value: byDay };
                } else if (byDay === 'WE') {
                  byDay = { label: 'Wednesday', value: byDay };
                } else if (byDay === 'TH') {
                  byDay = { label: 'Thursday', value: byDay };
                } else if (byDay === 'FR') {
                  byDay = { label: 'Friday', value: byDay };
                } else if (byDay === 'SA') {
                  byDay = { label: 'Saturday', value: byDay };
                } else if (byDay === 'SU') {
                  byDay = { label: 'Sunday', value: byDay };
                } else if (byDay === 'MO,TU,WE,TH,FR,SA,SU') {
                  let array = byDay.split(',');
                  byDay = { label: 'Day', value: array };
                } else if (byDay === 'MO,TU,WE,TH,FR') {
                  let array = byDay.split(',');
                  byDay = { label: 'Weekday', value: array };
                } else if (byDay === 'SA,SU') {
                  let array = byDay.split(',');
                  byDay = { label: 'Weekend Day', value: array };
                }
                BYSETPOS = object.rrule.split(' ')[1].split(';')[3].split('=')[1];
                if (BYSETPOS === '1') {
                  BYSETPOS = { label: 'First', value: 1 };
                } else if (BYSETPOS === '2') {
                  BYSETPOS = { label: 'Second', value: 2 };
                } else if (BYSETPOS === '3') {
                  BYSETPOS = { label: 'Third', value: 3 };
                } else if (BYSETPOS === '4') {
                  BYSETPOS = { label: 'Fourth', value: 4 };
                } else if (BYSETPOS === '5') {
                  BYSETPOS = { label: 'Last', value: 5 };
                }
                month = object.rrule.split(' ')[1].split(';')[4].split('=')[1];
                if (month === '1') {
                  month = { label: 'January', value: 1 };
                } else if (month === '2') {
                  month = { label: 'February', value: 2 };
                } else if (month === '3') {
                  month = { label: 'March', value: 3 };
                } else if (month === '4') {
                  month = { label: 'April', value: 4 };
                } else if (month === '5') {
                  month = { label: 'May', value: 5 };
                } else if (month === '6') {
                  month = { label: 'June', value: 6 };
                } else if (month === '7') {
                  month = { label: 'July', value: 7 };
                } else if (month === '8') {
                  month = { label: 'August', value: 8 };
                } else if (month === '9') {
                  month = { label: 'September', value: 9 };
                } else if (month === '10') {
                  month = { label: 'October', value: 10 };
                } else if (month === '11') {
                  month = { label: 'November', value: 11 };
                } else if (month === '12') {
                  month = { label: 'December', value: 12 };
                }
                count = object.rrule.split(' ')[1].split(';')[5].split('=')[0];
                if (count === 'COUNT') {
                  countValue = object.rrule.split(' ')[1].split(';')[5].split('=')[1];
                  ends = { label: 'After', value: 2 };
                } else if (count === 'UNTIL') {
                  untilString = object.rrule.split(' ')[1].split(';')[5].split('=')[1];
                  endDate = moment(untilString).format('YYYY-MM-DD HH:m:s').split(' ')[0];
                  endTime = dateTime(untilString);
                  ends = { label: 'On Day', value: 3 };
                }
                onYearDay = true;
              }
              object = {
                ...object,
                startDate: date,
                startTime: time,
                repeatFrequency: { label: 'Year', value: freq },
                every: Number(interval),
                months: month,
                monthsFor: month,
                yearDay: Number(byYearCount),
                onYearDay: onYearDay,
                rotation: BYSETPOS,
                selectDaysFor: byDay,
                occurrence: countValue ? Number(countValue) : null,
                endDate: endDate,
                endTime: endTime,
                end: ends,
              };
            }
          }
          object = {
            ...object,
            localTimeZone: { label: object.timezone, value: object.timezone },
          };
          dispatch(setScheduleObject(object));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
  };
};
export const editSchedule = (formData: any) => {
  console.log('projectFormobj', formData);
  return async (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    //let postData = { ...obj, organization: obj.organization.value };
    await httpService
      ._put(`${ansibleBaseURL}schedules/${formData.id}`, formData, { headers: ansibleHeaders })
      .then(async (response: any) => {
        console.log('projectForm', response);
        if (response && response.status === 200 && response.data.id > 0) {
          // dispatching final actions
          dispatch(setRequestSuccessful(true));
          dispatch(notifyApp(createSuccessNotification('Schedule added successfully', '')));
        } else {
          dispatch(setTemplateLoading(false));
          let keys = Object.keys(response.data);
          keys.forEach((i: string) => {
            response.data[i].forEach((er: any) => {
              dispatch(notifyApp(createErrorNotification('Error', er)));
            });
          });
        }
      })
      .catch((error) => {
        dispatch(setTemplateLoading(false));
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
  };
};
export const loadSurvey = () => {
  return async (dispatch: any) => {
    dispatch(setTemplateLoading(true));
    let id = localStorage.getItem(ansibleTemplateId);
    await httpService
      ._get(`${url}/${id}/survey_spec`, { headers: ansibleHeaders })
      .then((response: any) => {
        console.log('survey data', response);
        if (response && response.status === 200) {
          let final = response.data.spec;
          dispatch(templateSurveyList(final));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
      });
  };
};

/*export const deleteSurveyTemplate = () => {
  return async (dispatch: any) => {
    dispatch(setJobTemplateModalLoading(true));
    let id = localStorage.getItem(ansibleTemplateId);
    await httpService
      ._delete(`${ansibleBaseURL}job_templates/${id}/survey_spec`, { headers: ansibleHeaders })
      .then((response: any) => {
        if (response && response.status === 200) {
          dispatch(setRequestSuccessful(true));
          dispatch(notifyApp(createSuccessNotification(`'${name}' deleted successfully`, '')));
        } else {
          dispatch(notifyApp(createErrorNotification('Error', response.data.detail)));
        }
        dispatch(setJobTemplateModalLoading(false));
      })
      .catch((error) => {
        dispatch(notifyApp(createErrorNotification('Error', error)));
        dispatch(setJobTemplateModalLoading(false));
      });
  };
};

export const getSource = () => {
  const AccessId = '48v2wRzfK94y53sq5EuF';
  const AccessKey = 'H_D9i(f5~B^U36^K6i42=^nS~e75gy382Bf6{)P+';
  const Company = 'api';

  const httpVerb = 'GET';
  const resourcePath = '/setting/datasources';
  const queryParams = '';
  const data = '';

  let url = 'https://' + Company + '.logicmonitor.com/santaba/rest' + resourcePath + queryParams;

  const epoch = str(int(time.time() * 1000));

  const requestVars = httpVerb + epoch + data + resourcePath;

  const hmac1 = hmac.new(AccessKey.encode(), (msg = requestVars.encode()), (digestmod = hashlib.sha256)).hexdigest();
  const signature = base64.b64encode(hmac1.encode());

  const auth = 'LMv1 ' + AccessId + ':' + signature.decode() + ':' + epoch;
  const headers = { 'Content-Type': 'application/json', Authorization: auth };

  const response = httpService._get(url, {headers: headers});

  console.log('Response Status:', response.status_code);
  console.log('Response Body:', response.content);
};*/
