import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleCredential {
  credentialDetails: any[];
  isLoading: boolean;
  isModalLoading: boolean;
  credential: any;
  credentialList: any[];
  credentialPermission: any[];
  inputFields: any[];
  credentialCount: number;
  credentialPermissionCount: number;
  isRequestSuccessful: boolean;
}
const InitialState: AnsibleCredential = {
  credentialDetails: [],
  isLoading: false,
  isModalLoading: false,
  credential: {},
  credentialList: [],
  credentialPermission: [],
  inputFields: [],
  credentialCount: 0,
  credentialPermissionCount: 0,
  isRequestSuccessful: false,
};
// actions
export const setCredentialLoading = createAction<boolean>('credential/setCredentialLoading');
export const loadCredentialDetails = createAction<any>('credential/loadCredentialDetails');
export const setCredentialModalLoading = createAction<boolean>('credential/setCredentialModalLoading');
export const CredentialListLoaded = createAction<any>('credential/CredentialListLoaded');
export const setCredentialObject = createAction<any>('credential/setCredentialObject');
export const credentialPermissionList = createAction<any[]>('credential/credentialPermissionList');
export const setRequestSuccessful = createAction<boolean>('credential/setRequestSuccessful');
export const setInputFields = createAction<any[]>('credential/setInputFields');

const AnsibleCredentialReducer = createReducer(InitialState, {
  [setCredentialLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [loadCredentialDetails.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setCredentialModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setCredentialObject.type]: (state, action) => {
    state.credential = action.payload;
    state.isModalLoading = false;
    state.isLoading = false;
  },
  [CredentialListLoaded.type]: (state, action) => {
    state.credentialList = action.payload.results;
    state.credentialCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [credentialPermissionList.type]: (state, action) => {
    state.credentialPermission = action.payload.results;
    state.credentialPermissionCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [setInputFields.type]: (state, action) => {
    state.inputFields = action.payload;
  },
});

export default AnsibleCredentialReducer;
