import { createAction, createReducer } from '@reduxjs/toolkit';

export interface Schedule {
  scheduleList: any[];
  schedule: any;
  isLoading: boolean;
  isModalLoading: boolean;
}
const InitialState: Schedule = {
  scheduleList: [],
  schedule: {},
  isLoading: false,
  isModalLoading: false,
};
export const setScheduleLoading = createAction<boolean>('schedule/setScheduleLoading');
export const setScheduleModalLoading = createAction<boolean>('schedule/setScheduleModalLoading');
export const scheduleLoading = createAction<any[]>('schedule/scheduleLoading');
export const setScheduleObject = createAction<any>('schedule/setScheduleObject');

const ScheduleReducer = createReducer(InitialState, {
  [setScheduleLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setScheduleModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [scheduleLoading.type]: (state, action) => {
    state.scheduleList = action.payload;
    state.isLoading = false;
  },
  [setScheduleObject.type]: (state, action) => {
    state.schedule = action.payload;
    state.isLoading = false;
  },
});

export default ScheduleReducer;
