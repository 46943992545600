import { createAction, createReducer } from '@reduxjs/toolkit';

export interface HostReducer {
  hostList: any[];
  hostFacts: any[];
  groupList: any[];
  jobList: any[];
  host: any;
  group: any;
  isLoading: boolean;
  isModalLoading: boolean;
  hostCount: number;
  groupListCount: number;
  jobListCount: number;

  isRequestSuccessful: boolean;
}
const InitialState: HostReducer = {
  hostList: [],
  hostFacts: [],
  groupList: [],
  jobList: [],
  host: {},
  group: {},
  isLoading: false,
  isModalLoading: false,
  hostCount: 0,
  groupListCount: 0,
  jobListCount: 0,
  isRequestSuccessful: false,
};
// actions
export const setHostLoading = createAction<boolean>('host/setHostLoading');
export const hostListLoaded = createAction<any>('host/hostListLoaded');
export const setHostFactsObject = createAction<any>('host/setHostFactsObject');
export const groupListLoaded = createAction<any>('host/groupListLoaded');
export const jobListLoaded = createAction<any>('host/jobListLoaded');
export const setHostModalLoading = createAction<boolean>('host/setHostModalLoading');
export const setHostObject = createAction<any>('host/setHostObject');
export const setGroupObject = createAction<any>('host/setGroupObject');
export const setRequestSuccessful = createAction<any>('host/setRequestSuccessful');
export const setExistingGroupsModalLoading = createAction<boolean>('host/setOrgModalLoading');

const AnsibleHostReducer = createReducer(InitialState, {
  [setHostLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setExistingGroupsModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setHostModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [hostListLoaded.type]: (state, action) => {
    state.isLoading = false;
    state.hostList = action.payload.results;
    state.hostCount = action.payload.count;
    state.isRequestSuccessful = false;
  },
  [groupListLoaded.type]: (state, action) => {
    state.isLoading = false;
    state.groupList = action.payload.results;
    state.groupListCount = action.payload.count;
    state.isRequestSuccessful = false;
  },
  [jobListLoaded.type]: (state, action) => {
    state.isLoading = false;
    state.jobList = action.payload.results;
    state.jobListCount = action.payload.count;
    state.isRequestSuccessful = false;
  },
  [setHostObject.type]: (state, action) => {
    state.host = action.payload;
    state.isModalLoading = false;
    state.isLoading = false;
  },
  [setGroupObject.type]: (state, action) => {
    state.group = action.payload;
    state.isModalLoading = false;
    state.isLoading = false;
  },
  [setHostFactsObject.type]: (state, action) => {
    state.hostFacts = action.payload.results;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
});

export default AnsibleHostReducer;
