import React from 'react';
import { Redirect } from 'react-router-dom';
import { SafeDynamicImport } from 'app/core/components/DynamicImports/SafeDynamicImport';
import { config } from 'app/core/config';
import { RouteDescriptor } from 'app/core/navigation/types';

const dataCollectorRoutes = [
  {
    path: '/dataCollector',
    // eslint-disable-next-line react/display-name
    component: () => <Redirect to="/dataCollector/dag" />,
  },
  {
    path: '/dataCollector/dag',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/dag/DagList')
    ),
  },
  {
    path: '/dataCollector/dag/dagdetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/dag/DagDetail')
    ),
  },
  {
    path: '/dataCollector/dag/dagTree',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/dag/DagTree')
    ),
  },
  {
    path: '/dataCollector/dag/dagGraph',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/dag/Graph')
    ),
  },
  {
    path: '/dataCollector/dag/calender',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/dag/Calender')
    ),
  },
  {
    path: '/dataCollector/dag/code',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/dag/Code')
    ),
  },
  {
    path: '/dataCollector/dag/taskDuration',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/dag/TaskDuration')
    ),
  },
  {
    path: '/dataCollector/dag/taskTries',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/dag/TaskTries')
    ),
  },
  {
    path: '/dataCollector/dag/landingTimes',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/dag/LandingTimes')
    ),
  },
  {
    path: '/dataCollector/dag/gantt',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/dag/Gantt')
    ),
  },
  {
    path: '/dataCollector/user',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/user/userList')
    ),
  },
  {
    path: '/dataCollector/role',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/role/RoleList')
    ),
  },
  {
    path: '/dataCollector/actions',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/actions/ActionList')
    ),
  },
  {
    path: '/dataCollector/resources',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/resources/Resources')
    ),
  },
  {
    path: '/dataCollector/permission',
    component: SafeDynamicImport(
      () =>
        import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/permisions/PermissionsList')
    ),
  },
  {
    path: '/dataCollector/user/userdetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/user/UserDetail')
    ),
  },
  {
    path: '/dataCollector/role/roledetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/role/RoleDetail')
    ),
  },

  {
    path: '/dataCollector/dag/trigerView',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/dataCollector/dag/DagTriggerConfig')
    ),
  },
];

export function getDataCollectorRoutes(cfg = config): RouteDescriptor[] {
  return dataCollectorRoutes;
}
