import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleTemplate {
  templateDetails: any[];
  isLoading: boolean;
  isJobModalLoading: boolean;
  template: any;
  survey: any;
  schedule: any;
  templateList: any[];
  workTemplateList: any[];
  templateNotificationList: any[];
  templateSchedule: any[];
  templateSurvey: any[];
  templatePermission: any[];
  workFlowPendingList: any[];
  workFlowPendingListCount: number;
  templateCompletedJobs: any;
  templateCount: number;
  workFlowCount: number;
  workTemplateCount: number;
  templatePermissionCount: number;
  templateCompletedJobsCount: number;
  templateScheduleCount: number;
  templateNotificationListCount: 0;
  isRequestSuccessful: boolean;
  isWorkFlowLoading: boolean;
  isFlowPendingListLoading: boolean;
  canStartWithoutUserInput: boolean;
  launchModalData: any;
  launchWorkFlowTemp: any;
}
const InitialState: AnsibleTemplate = {
  templateDetails: [],
  isLoading: false,
  isWorkFlowLoading: false,
  isJobModalLoading: false,
  isFlowPendingListLoading: false,
  template: {},
  survey: {},
  schedule: {},
  templateList: [],
  workTemplateList: [],
  workFlowPendingList: [],
  templateNotificationList: [],
  templateSchedule: [],
  templateSurvey: [],
  templatePermission: [],
  templateCompletedJobs: [],
  templateCount: 0,
  workTemplateCount: 0,
  templatePermissionCount: 0,
  workFlowPendingListCount: 0,
  templateScheduleCount: 0,
  templateNotificationListCount: 0,
  templateCompletedJobsCount: 0,
  workFlowCount: 0,
  isRequestSuccessful: false,
  canStartWithoutUserInput: false,
  launchModalData: null,
  launchWorkFlowTemp: [],
};
// actions
export const setTemplateLoading = createAction<boolean>('template/setTemplateLoading');
export const setWorkFlowPendingListLoading = createAction<boolean>('template/setWorkFlowPendingListLoading');
export const setWorkTemplateLoading = createAction<boolean>('template/setWorkTemplateLoading');
export const loadTemplateDetails = createAction<any>('template/loadTemplateDetails');
export const setJobTemplateModalLoading = createAction<boolean>('template/setJobTemplateModalLoading');
export const templateListLoaded = createAction<any>('template/templateListLoaded');
export const workFlowPendingListLoaded = createAction<any>('template/workFlowPendingListLoaded');
export const workTemplateListLoaded = createAction<any>('template/workTemplateListLoaded');
export const setTemplateObject = createAction<any>('template/setTemplateObject');
export const setSurveyObject = createAction<any>('template/setSurveyObject');
export const setScheduleObject = createAction<any>('template/setScheduleObject');
export const setTemplateOrganization = createAction<any>('template/setTemplateOrganization');
export const templateNotificactionList = createAction<any[]>('template/templateOrganizationList');
export const templateScheduleList = createAction<any[]>('template/templateScheduleList');
export const templateSurveyList = createAction<any[]>('template/templateSurveyList');
export const templatePermissionList = createAction<any[]>('template/templatePermissionList');
export const setRequestSuccessful = createAction<boolean>('template/setRequestSuccessful');
export const TemplateCompletedJob = createAction<any[]>('template/TemplateCompletedJob');
export const setLaunchModalData = createAction<any>('template/setLaunchModalData');
export const setLaunchWorkFlowTemp = createAction<any>('template/launchWorkFlowTemp');

const AnsibleTemplateReducer = createReducer(InitialState, {
  [setTemplateLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setWorkFlowPendingListLoading.type]: (state, action) => {
    state.isFlowPendingListLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setWorkTemplateLoading.type]: (state, action) => {
    state.isWorkFlowLoading = action.payload;
  },
  [templateNotificactionList.type]: (state, action) => {
    state.isJobModalLoading = false;
    state.templateNotificationList = action.payload.results;
    state.templateNotificationListCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [workFlowPendingListLoaded.type]: (state, action) => {
    state.workFlowPendingList = action.payload.results;
    state.workFlowPendingListCount = action.payload.count;
    state.isFlowPendingListLoading = false;
    state.isRequestSuccessful = false;
  },
  [loadTemplateDetails.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setJobTemplateModalLoading.type]: (state, action) => {
    state.isJobModalLoading = action.payload;
  },
  [setTemplateObject.type]: (state, action) => {
    state.template = action.payload;
    state.isJobModalLoading = false;
    state.isLoading = false;
  },
  [setSurveyObject.type]: (state, action) => {
    state.survey = action.payload;
    state.isJobModalLoading = false;
    state.isLoading = false;
  },
  [setScheduleObject.type]: (state, action) => {
    state.schedule = action.payload;
    state.isLoading = false;
    state.isJobModalLoading = false;
  },
  [templateListLoaded.type]: (state, action) => {
    state.templateList = action.payload.results;
    state.templateCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [workTemplateListLoaded.type]: (state, action) => {
    state.workTemplateList = action.payload.results;
    state.workTemplateCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
    state.isWorkFlowLoading = false;
  },
  [setTemplateOrganization.type]: (state, action) => {
    state.templateList = action.payload;
    state.isLoading = false;
  },
  [templateScheduleList.type]: (state, action) => {
    state.templateSchedule = action.payload.results;
    state.templateScheduleCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [templateSurveyList.type]: (state, action) => {
    state.templateSurvey = action.payload;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [templatePermissionList.type]: (state, action) => {
    state.templatePermission = action.payload.results;
    state.templatePermissionCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [TemplateCompletedJob.type]: (state, action) => {
    state.templateCompletedJobs = action.payload.results;
    state.templateCompletedJobsCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [setLaunchModalData.type]: (state, action) => {
    state.launchModalData = action.payload;
    state.isLoading = false;
  },
  [setLaunchWorkFlowTemp.type]: (state, action) => {
    state.launchWorkFlowTemp = action.payload;
    state.isLoading = false;
  },
});

export default AnsibleTemplateReducer;
