import { createAction, createReducer } from '@reduxjs/toolkit';

export interface CredentialTypeReducer {
  credentialTypeList: any[];
  credentialTypeCount: number;
  credentialType: any;
  isLoading: boolean;
  isRequestSuccessful: boolean;
}
const InitialState: CredentialTypeReducer = {
  credentialTypeList: [],
  credentialTypeCount: 0,
  credentialType: {},
  isLoading: false,
  isRequestSuccessful: false,
};
// actions
export const setCredentialTypeLoading = createAction<boolean>('credentialType/setCredentialTypeLoading');
export const credentialTypeListLoaded = createAction<any>('credentialType/CredentialTypeListLoaded');
export const setCredentialTypeObject = createAction<any>('credentialType/setCredentialTypeObject');
export const setRequestSuccessful = createAction<boolean>('credentialType/setRequestSuccessful');

const AnsibleCredentialTypeReducer = createReducer(InitialState, {
  [setCredentialTypeLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [credentialTypeListLoaded.type]: (state, action) => {
    state.credentialTypeList = action.payload.results;
    state.credentialTypeCount = action.payload.count;
    state.isLoading = false;
    state.isRequestSuccessful = false;
  },
  [setCredentialTypeObject.type]: (state, action) => {
    state.credentialType = action.payload;
    state.isLoading = false;
  },
});

export default AnsibleCredentialTypeReducer;
