import React from 'react';
import { Redirect } from 'react-router-dom';
import { SafeDynamicImport } from 'app/core/components/DynamicImports/SafeDynamicImport';
import { config } from 'app/core/config';
import { RouteDescriptor } from 'app/core/navigation/types';

const automateRoutes = [
  {
    path: '/automation',
    // eslint-disable-next-line react/display-name
    component: () => <Redirect to="/automation/dashboard" />,
  },
  {
    path: '/automation/dashboard',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationDashboard" */ 'app/features/automation/dashboard')
    ),
  },
  {
    path: '/automation/inventory',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationInventory" */ 'app/features/automation/inventory')
    ),
  },
  {
    path: '/automation/tickets',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationTickets" */ 'app/features/automation/tickets')
    ),
  },
  {
    path: '/automation/activity-log',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationActivityLog" */ 'app/features/automation/activity-log')
    ),
  },
  {
    path: '/automation/classification',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationClassification" */ 'app/features/automation/classification')
    ),
  },
  {
    path: '/automation/schedule',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationSchedule" */ 'app/features/automation/schedule/ScheduleList')
    ),
  },
  {
    path: '/automation/classification/classificationDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationClassification" */ 'app/features/automation/classification/detail')
    ),
  },
  {
    path: '/automation/schedule/scheduleDetail/:id',
    component: SafeDynamicImport(
      () => import(/* webpackChunkName: "AutomationScheduleDetail" */ 'app/features/automation/schedule/ScheduleDetail')
    ),
  },
  {
    path: '/automation/inventory/inventoryDetail/:id',
    component: SafeDynamicImport(
      () =>
        import(/* webpackChunkName: "AutomationInventoryDetail" */ 'app/features/automation/inventory/InventoryDetail')
    ),
  },
];

export function getAutomateRoutes(cfg = config): RouteDescriptor[] {
  return automateRoutes;
}
