import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleDataCollector {
  isLoading: boolean;
  isModalLoading: boolean;
  isRequestSuccessful: boolean;
  DataCollector: any;
  DagGridList: any;
  DagGantt: any;
  resultValues: any;
  detailList: any;
  code: string;
  graphView: any;
}
const InitialState: AnsibleDataCollector = {
  isLoading: false,
  isModalLoading: false,
  isRequestSuccessful: false,
  DataCollector: [],
  DagGridList: [],
  DagGantt: [],
  resultValues: [],
  detailList: {},
  code: '',
  graphView: {},
};
// actions
export const setDataCollectorLoading = createAction<boolean>('DataCollector/setJobLoading');
export const setDCModalLoading = createAction<boolean>('DataCollector/setDCModalLoading');
export const setRequestSuccessful = createAction<boolean>('DataCollector/setRequestSuccessful');
export const dataCollectorListLoaded = createAction<any>('DataCollector/DataCollectorListLoaded');
export const dataGridListLoad = createAction<any>('DataCollector/dataGridListLoad');
export const dataGanttLoad = createAction<any>('DataCollector/dataGanttLoad');
export const resultBox = createAction<any>('DataCollector/colorsBox');
export const detaildata = createAction<any>('DataCollector/detaildata');
export const dagGraphData = createAction<any>('DataCollector/dagGraphData');
export const getCode = createAction<string>('DataCollector/getCode');

const AnsibleDataCollectorReducer = createReducer(InitialState, {
  [setDataCollectorLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setDCModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [dataCollectorListLoaded.type]: (state, action) => {
    state.isLoading = false;
    state.DataCollector = action.payload;
  },
  [dataGridListLoad.type]: (state, action) => {
    state.isLoading = false;
    state.DagGridList = action.payload;
  },
  [dataGanttLoad.type]: (state, action) => {
    state.isLoading = false;
    state.DagGantt = action.payload;
  },
  [resultBox.type]: (state, action) => {
    state.isLoading = false;
    state.resultValues = action.payload;
  },
  [detaildata.type]: (state, action) => {
    state.isLoading = false;
    state.detailList = action.payload;
  },
  [getCode.type]: (state, action) => {
    state.isLoading = false;
    state.code = action.payload;
  },
  [dagGraphData.type]: (state, action) => {
    state.isLoading = false;
    state.graphView = action.payload;
  },
});
export default AnsibleDataCollectorReducer;
