import { createAction, createReducer } from '@reduxjs/toolkit';

export interface RtsConfig {
  isLoading: boolean;
  isModalLoading: boolean;
  config: any;
  isRequestSuccessful: boolean;
}

const InitialState: RtsConfig = {
  isLoading: false,
  isModalLoading: false,
  config: {},
  isRequestSuccessful: false,
};
export const setConfigLoading = createAction<boolean>('config/setConfigLoading');
export const setConfigModalLoading = createAction<boolean>('config/setConfigModalLoading');
export const setConfigObject = createAction<any>('config/setConfigObject');
export const setRequestSuccessful = createAction<any>('config/setRequestSuccessful');

const RtsConfigReducer = createReducer(InitialState, {
  [setConfigLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setConfigModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setConfigObject.type]: (state, action) => {
    state.config = action.payload;
    state.isModalLoading = false;
    state.isLoading = false;
  },
});
export default RtsConfigReducer;
