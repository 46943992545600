import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleJobs {
  isLoading: boolean;
  isModalLoading: boolean;
  jobs: any;
  isRequestSuccessful: boolean;
}
const InitialState: AnsibleJobs = {
  isLoading: false,
  isModalLoading: false,
  jobs: {},
  isRequestSuccessful: false,
};
// actions
export const setJobsLoading = createAction<boolean>('jobs/setJobsLoading');
export const setJobsModalLoading = createAction<boolean>('jobs/setJobsModalLoading');
export const setJobsObject = createAction<any>('jobs/setJobObsject');
export const setRequestSuccessful = createAction<boolean>('jobs/setRequestSuccessful');

const AnsibleSettingsJobsReducer = createReducer(InitialState, {
  [setJobsLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setJobsModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setJobsObject.type]: (state, action) => {
    state.jobs = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
});

export default AnsibleSettingsJobsReducer;
