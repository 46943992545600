import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AIML {
  isLoading: boolean;
  dataList: any[];
}

const InitialState: AIML = {
  dataList: [],
  isLoading: false,
};
export const setLoading = createAction<boolean>('aiml/setAIMLLoading');
export const setDataListLoading = createAction<boolean>('aiml/setAIMLDataListLoading');

const AIMLReducer = createReducer(InitialState, {
  [setLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setDataListLoading.type]: (state, action) => {
    state.dataList = action.payload;
    state.isLoading = false;
  },
});

export default AIMLReducer;
