import { createAction, createReducer } from '@reduxjs/toolkit';

export interface AnsibleAuthentication {
  isLoading: boolean;
  isModalLoading: boolean;
  authentication: any;
  isRequestSuccessful: boolean;
}
const InitialState: AnsibleAuthentication = {
  isLoading: false,
  isModalLoading: false,
  authentication: {},
  isRequestSuccessful: false,
};
// actions
export const setAuthenticationLoading = createAction<boolean>('authentication/setAuthenticationLoading');
export const setAuthenticationModalLoading = createAction<boolean>('authentication/setAuthenticationModalLoading');
export const setAuthenticationObject = createAction<any>('authentication/setAuthenticationObject');
export const setRequestSuccessful = createAction<boolean>('authentication/setRequestSuccessful');

const AnsibleAuthenticationReducer = createReducer(InitialState, {
  [setAuthenticationLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setAuthenticationModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setAuthenticationObject.type]: (state, action) => {
    state.authentication = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
  },
});

export default AnsibleAuthenticationReducer;
