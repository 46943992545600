// Libraries
import React, { FC, HTMLAttributes, useEffect } from 'react';
import { getTitleFromNavModel } from 'app/core/selectors/navModel';

// Components
import PageHeader from '../PageHeader/PageHeader';
import { Footer } from '../Footer/Footer';
import { PageContents } from './PageContents';
import { CustomScrollbar, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2, LiveChannelEvent, LiveChannelMessageEvent, NavModel } from '@grafana/data';
import { Branding } from '../Branding/Branding';
import { css, cx } from '@emotion/css';
import { getGrafanaLiveSrv, RefreshEvent } from '@grafana/runtime/src';
import { notifyApp } from '../../reducers/appNotification';
import { dispatch } from 'app/store/store';
import { createInfoNotification } from 'app/core/copy/appNotification';
import appEvents from '../../app_events';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  navModel?: NavModel;
  goBackBtn?: () => void;
}

export interface PageType extends FC<Props> {
  Header: typeof PageHeader;
  Contents: typeof PageContents;
}

export const Page: PageType = ({ navModel, children, className, goBackBtn, ...otherProps }) => {
  const styles = useStyles2(getStyles);

  useEffect(() => {
    if (navModel) {
      const title = getTitleFromNavModel(navModel);
      document.title = title ? `${title} - ${Branding.AppTitle}` : Branding.AppTitle;
    } else {
      document.title = Branding.AppTitle;
    }

    let stream = getGrafanaLiveSrv().getStream<any>({
      scope: 'rts',
      namespace: 'automation',
      path: 'service-now-update',
    });
    let wfStream = getGrafanaLiveSrv().getStream<any>({
      scope: 'rts',
      namespace: 'automation',
      path: 'workflow-update',
    });

    let sSub = stream.subscribe((value: LiveChannelEvent) => {
      console.log('On Stream!!! Service Now=> ', value);
      let s = value as LiveChannelMessageEvent<any>;
      if (s.message !== undefined && s.message.number !== undefined) {
        dispatch(notifyApp(createInfoNotification(`Change Ticket#${s.message.number} was Updated!!`, '')));
        appEvents.publish(RefreshEvent);
      }
    });
    let wfSub = wfStream.subscribe((value: LiveChannelEvent) => {
      console.log('On Stream!!! Workflow Update=> ', value);
      let s = value as LiveChannelMessageEvent<any>;
      if (s.message !== undefined && s.message.name !== undefined) {
        dispatch(
          notifyApp(
            createInfoNotification(`Workflow Updated!`, 'Name: ' + s.message.name + ' Status: ' + s.message.status)
          )
        );
        appEvents.emit('ReloadData', true);
      }
      if (s.message !== undefined && s.message.body !== undefined) {
        dispatch(notifyApp(createInfoNotification(s.message.body)));
        appEvents.publish(RefreshEvent);
      }
    });
    return function cleanup() {
      sSub.unsubscribe();
      wfSub.unsubscribe();
    };
  }, [navModel]);

  return (
    <div {...otherProps} className={cx(styles.wrapper, className)}>
      <CustomScrollbar autoHeightMin={'100%'}>
        <div className="page-scrollbar-content">
          {navModel && <PageHeader goBackBtn={goBackBtn} model={navModel} />}
          {children}
          <Footer />
        </div>
      </CustomScrollbar>
    </div>
  );
};

Page.Header = PageHeader;
Page.Contents = PageContents;

export default Page;

const getStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    width: 100%;
    flex-grow: 1;
    width: 100%;
    min-height: 0;
  `,
});
