import { createAction, createReducer } from '@reduxjs/toolkit';

export interface DashboardReducer {
  isLoading: boolean;
  isModalLoading: boolean;
  isRequestSuccessful: boolean;
  hosts: number;
  failedhosts: number;
  inventories: number;
  inventorySyncFailed: number;
  projects: number;
  projectSyncFailure: number;
  templateList: any;
  dashboardList: any;
  dashboardGraph: any;
  joblist: any;
  graphDetails: any;
}
const InitialState: DashboardReducer = {
  isLoading: false,
  isModalLoading: false,
  isRequestSuccessful: false,
  hosts: 0,
  failedhosts: 0,
  inventories: 0,
  inventorySyncFailed: 0,
  projects: 0,
  projectSyncFailure: 0,
  templateList: {},
  dashboardList: {},
  dashboardGraph: {},
  joblist: {},
  graphDetails: {},
};
// actions
export const setDashboardLoading = createAction<boolean>('dashboard/setDashboardLoading');
export const setDashboardCounts = createAction<boolean>('dashboard/setDashboardCounts');
export const setDashboardGraph = createAction<boolean>('dashboard/setDashboardGraph');
export const setDashboardModalLoading = createAction<boolean>('dashboard/setDashboardModalLoading');
export const setRequestSuccessful = createAction<any>('dashboard/setRequestSuccessful');
export const setGraphDetails = createAction<any>('dashboard/setGraphDetails');
export const setDashboardHost = createAction<number>('dashboard/setDashboardHost');
export const setDashboardFailedHost = createAction<number>('dashboard/setDashboardFailedHost');
export const setInventories = createAction<number>('dashboard/setInventories');
export const setInventoriesSyncFailed = createAction<number>('dashboard/setInventoriesSyncFailed');
export const setProjects = createAction<number>('dashboard/setProjects');
export const setProjectsSyncFailed = createAction<number>('dashboard/setProjectsSyncFailed');
export const setTemplateList = createAction<any[]>('dashboard/setTemplateList');
export const setJobList = createAction<any[]>('dashboard/setJobList');

const AnsibleDashboardReducer = createReducer(InitialState, {
  [setDashboardLoading.type]: (state, action) => {
    state.isLoading = action.payload;
  },
  [setDashboardCounts.type]: (state, action) => {
    state.dashboardList = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
    state.isRequestSuccessful = false;
  },
  [setDashboardGraph.type]: (state, action) => {
    state.dashboardGraph = action.payload;
    state.isLoading = false;
    state.isModalLoading = false;
    state.isRequestSuccessful = false;
  },
  [setRequestSuccessful.type]: (state, action) => {
    state.isRequestSuccessful = action.payload;
  },
  [setDashboardModalLoading.type]: (state, action) => {
    state.isModalLoading = action.payload;
  },
  [setDashboardHost.type]: (state, action) => {
    state.hosts = action.payload;
    state.isLoading = false;
  },
  [setTemplateList.type]: (state, action) => {
    state.templateList = action.payload;
    state.isLoading = false;
  },
  [setDashboardFailedHost.type]: (state, action) => {
    state.failedhosts = action.payload;
    state.isLoading = false;
  },

  [setInventories.type]: (state, action) => {
    state.inventories = action.payload;
    state.isLoading = false;
  },
  [setGraphDetails.type]: (state, action) => {
    state.graphDetails = action.payload;
    state.isLoading = false;
  },

  [setInventoriesSyncFailed.type]: (state, action) => {
    state.inventorySyncFailed = action.payload;
    state.isLoading = false;
  },

  [setProjects.type]: (state, action) => {
    state.projects = action.payload;
    state.isLoading = false;
  },

  [setProjectsSyncFailed.type]: (state, action) => {
    state.projectSyncFailure = action.payload;
    state.isLoading = false;
  },

  [setJobList.type]: (state, action) => {
    state.joblist = action.payload;
    state.isLoading = false;
  },
});

export default AnsibleDashboardReducer;
