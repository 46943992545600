import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Organization, OrganizationState } from 'app/types';

export const initialState: OrganizationState = {
  organization: {} as Organization,
};

const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    organizationLoaded: (state, action: PayloadAction<Organization>): OrganizationState => {
      console.log('Organization Loaded State', action.payload);
      return { ...state, organization: action.payload };
    },
    setOrganizationName: (state, action: PayloadAction<Organization>): OrganizationState => {
      console.log('Updated State', action.payload);
      return {
        ...state,
        organization: { ...state.organization, name: action.payload.name, showLogo: action.payload.showLogo },
      };
    },
  },
});

export const { setOrganizationName, organizationLoaded } = organizationSlice.actions;

export const organizationReducer = organizationSlice.reducer;

export default {
  organization: organizationReducer,
};
